import axios from 'axios';
import { ADD_TRUSTED_SOFTWARE, GET_TRUSTED_SOFTWARE, PORTS_REPORTS, SERVICE_REPORTS_DOWNLOAD, SOFTWARE_INVENTORY_REPORTS, SOFTWARE_INVENTORY_REPORTS_DOWNLOAD, SOFTWARE_VULNERABILITY_REPORTS_DOWNLOAD, TRUSTED_SOFTWARE_VALIDATION, UPLOAD_TRUSTED_SOFTWARE } from './constants/swaggerConfig';



export const getTrustedSoftware = (token :string,name:string,version:string,publisher:string,license:string) => {
    return axios.get(`${GET_TRUSTED_SOFTWARE}/${token}?name=${name}&version=${version}&publisher=${publisher}&license=${license}`)
}
export const uploadTrustedSoftware = (token :string,formData:any) => {
    return axios.post(`${UPLOAD_TRUSTED_SOFTWARE}/${token}`,formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }}
    )
}

export const getXmlSchema = (token:string) => {
    return axios.get(`${ADD_TRUSTED_SOFTWARE}/download/xmlSchema/${token}`)
}
export const getCSVSchema = (token:string) => {
    return axios.get(`${ADD_TRUSTED_SOFTWARE}/download/csvSchema/${token}`)
}

export const addTrustedSoftware = (token:string,userData:any) => {
    return axios.post(`${ADD_TRUSTED_SOFTWARE}/${token}`,userData)
}

export const putTrustedSoftware = (token:string,userData:any) => {
    return axios.put(`${ADD_TRUSTED_SOFTWARE}/${token}`,userData)
}
export const getSoftwareInventoryReports = (token:string,agentId:string) => {
    return axios.get(`${SOFTWARE_INVENTORY_REPORTS}/${token}?agentId=${agentId}`,)
}
export const getPortsReports = (token:string,agentId:string) => {
    return axios.get(`${PORTS_REPORTS}/${token}?agentId=${agentId}`,)
}
export const getSoftwareInventoryReportsDownload = (token:string,agentId:string,download:boolean) => {
    return axios.get(`${SOFTWARE_INVENTORY_REPORTS_DOWNLOAD}/${token}?agentId=${agentId}&download=${download}`,)
}
export const ServiceReportsDownload = (token:string,agentId:string,download:boolean) => {
    return axios.get(`${SERVICE_REPORTS_DOWNLOAD}/${token}?agentId=${agentId}&download=${download}`,)
}
export const VulnerabilityReportsDownload = (token:string,agentId:string,download:boolean) => {
    return axios.get(`${SOFTWARE_VULNERABILITY_REPORTS_DOWNLOAD}/${token}?agentId=${agentId}&download=${download}`,)
}
export const getSoftwareValidated = (token:string,userData:string[]) => {
    return axios.post(`${TRUSTED_SOFTWARE_VALIDATION}/${token}`,userData)
}