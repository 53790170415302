/* eslint-disable prettier/prettier */
import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Row, Table} from 'react-bootstrap';
import moment from 'moment';
import {ContentHeader} from '@app/components';
import Container from '@app/components/CustomDash/Container';
import {
  swaggerGetAgentDetails,
  getAgentSoftwareDetails,
  patchAgentAlias,
  deactivateAgent,
  agentFunctionValues,
  patchAgentFunction,
  getAgentAppUsage,
  swaggerGetAgentBrowseHistoryCount,
  swaggerGetAgentBrowseHistoryList,
  getAgentService
} from '@app/services/agents';
import {
  convertUTCToMilliseconds,
  formatBytes,
  formatDate,
  formatDatetimeToDate,
  formatTime
} from '@app/utils/helpers';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
// import SoftwareDetailsComponent from './SoftwareDetailsComponent';
import Pagination from '@app/components/common/Pagination';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';
import {toast} from 'react-toastify';
import NoOfRecords from '@app/components/common/NoOfRecords';
import {getSoftwareValidated} from '@app/services/trustedSoftware';
import {error} from 'console';
import {type} from 'os';
import AppUsageTable from './AppUsageTable';
import AgentService from './AgentService';
import BrowserHistory from './BrowserHistory';
import Calendar from 'react-calendar';

const AgentDetails = () => {
  const [activeTab, setActiveTab] = useState('agentInfo');
  const {agentId} = useParams();
  const navigate = useNavigate();

  const [agentDetails, setAgentDetails] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  const [name, setName] = useState('');
  const [publisher, setPublisher] = useState('');
  const [state, setState] = useState('');
  const [version, setVersion] = useState('');
  const [aliasName, setAliasName] = useState('');
  const [deactivate, setDeactivate] = useState(false);
  const [comment, setComment] = useState('');
  const [functionRoles, setFunctionRoles] = useState([]);
  const [validationList, setValidationList] = useState<string[]>([]);
  const [functionValue, setFunctionValue] = useState('');
  const [agentSoftwareDetails, setAgentSoftwareDetails] = useState<any>(null);
  const [softwareDetailsPageNo, setSoftwareDetailsPageNo] = useState(1);
  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);
  const [appUsageList, setAppUsageList] = useState<any>(null);
  const [serviceList, setServiceList] = useState<any>(null);
  const [HistoryList, setHistoryList] = useState<any>(null);
  const [historyCount, setHistoryCount] = useState(100);
  const [appUsagePageNo, setAppUsagePageNo] = useState(1);
  const [appUsageNoOfRecords, setAppUsageNoOfRecords] =
    useState(RECORDS_PER_PAGE);
  const [historyPageNo, setHistoryPageNo] = useState(1);
  const [historyNoOfRecords, setHistoryNoOfRecords] =
    useState(RECORDS_PER_PAGE);
  const [servicePageNo, setServicePageNo] = useState(1);
  const [serviceNoOfRecords, setServiceNoOfRecords] =
    useState(RECORDS_PER_PAGE);
  const [browserType, setBrowserType] = useState('');
  const [domain, setDomain] = useState('');
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [dateRange, setDateRange] = useState('');
  const [showDateStart, setShowDateStart] = useState(false);
  const [showDateEnd, setShowDateEnd] = useState(false);
  const [calendarDateStart, setCalendarDateStart] = useState<any>(new Date());
  const [calendarDateEnd, setCalendarDateEnd] = useState<any>(new Date());
  const [clearInput, setClearInput] = useState(false);

  const dateStartCalendarRef = useRef(null);
  const dateEndCalendarRef = useRef(null);

  const token = useSelector((state: any) => state.auth.token);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  const isAdmin = localStorage.getItem('role');
  const userId = localStorage.getItem('userId') || '';

  // Perform array slicing based on the current page
  const startIndex = (softwareDetailsPageNo - 1) * numberOfRecords;
  const endIndex = softwareDetailsPageNo * numberOfRecords;
  const softwarePaginatedData = agentSoftwareDetails?.slice(
    startIndex,
    endIndex
  );

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  // TODO FIX BUGS
  const sortTableData = (column: any) => {
    const sortedData = [...agentSoftwareDetails];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      if (sortOrder === 'asc' || sortOrder === null) {
        // sort aesc if the current order is asc or null
        sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
        setSortColumn(column);
        setSortOrder('asc');
      } else {
        sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
        setSortColumn(column);
        setSortOrder('desc');
      }
    }

    setAgentSoftwareDetails(sortedData);
  };

  const appUsageStartIndex = (appUsagePageNo - 1) * appUsageNoOfRecords;
  const appUsageEndIndex = appUsagePageNo * appUsageNoOfRecords;
  const appUsagePaginatedData = appUsageList?.slice(
    appUsageStartIndex,
    appUsageEndIndex
  );
  const historyStartIndex = (historyPageNo - 1) * historyNoOfRecords;
  const historyEndIndex = historyPageNo * historyNoOfRecords;
  const historyPaginatedData = HistoryList?.slice(
    historyStartIndex,
    historyEndIndex
  );
  const serviceStartIndex = (servicePageNo - 1) * serviceNoOfRecords;
  const serviceEndIndex = servicePageNo * serviceNoOfRecords;
  const servicePaginatedData = serviceList?.slice(
    serviceStartIndex,
    serviceEndIndex
  );

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const getAgentDetails = () => {
    swaggerGetAgentDetails(token, agentId)
      .then((response) => {
        const {data} = response;
        setAgentDetails(data);
        setAliasName(data.alias);
        setFunctionValue(data.function);
        setDeactivate(!data.active);
        setComment(data.active ? '' : data.comment);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchAgentSoftwareDetails = () => {
    getAgentSoftwareDetails(
      token,
      agentId,
      name,
      publisher,
      state,
      version
    ).then((res) => {
      const {data} = res;
      setAgentSoftwareDetails(data);
    });
  };

  const fetchAppUsage = () => {
    getAgentAppUsage(token, agentId).then((response) => {
      const {data} = response;
      setAppUsageList(data);
    });
  };
  const fetchHistoryCount = () => {
    swaggerGetAgentBrowseHistoryCount(token, agentId).then((response) => {
      const {data} = response;
      setHistoryCount(data);
    });
  };
  const fetchHistoryList = () => {
    swaggerGetAgentBrowseHistoryList(
      token,
      agentId,
      historyCount,
      browserType,
      domain,
      dateStart ? convertUTCToMilliseconds(dateStart).toString(): dateStart,
      dateEnd ? convertUTCToMilliseconds(dateEnd).toString(): dateEnd
    ).then((response) => {
      const {data} = response;
      setHistoryList(data);
    });
  };

  const fetchService = () => {
    getAgentService(token, agentId).then((response) => {
      const {data} = response;
      setServiceList(data);
    });
  };

  useEffect(() => {
  
    if (token && agentId) {
        fetchService();
    }
  }, []);

  const getAgentFunctionValues = () => {
    agentFunctionValues(token)
      .then((response) => {
        const {data} = response;
        setFunctionRoles(
          Object.entries(data).map(([key, value]) => ({key, value}))
        );
      })
      .catch((err) => {});
  };

  const updateAliasName = () => {
    patchAgentAlias(token, agentDetails.id, aliasName)
      .then((res) => {
        toast.success('Updated Alias Agent');
      })
      .catch((error) => {
        toast.error('Failed to Update Alias Agent');
      });
  };

  const updateFunctionValue = () => {
    patchAgentFunction(token, agentDetails.id, functionValue)
      .then((res) => {
        toast.success('Updated Function value');
      })
      .catch((error) => {
        toast.error('Failed to Update Function value');
      });
  };

  const getCurrentDateTime = () => {
    const current = new Date();
    const date = ('0' + current.getDate()).slice(-2);
    const month = ('0' + (current.getMonth() + 1)).slice(-2);
    const year = current.getFullYear();
    const hours = ('0' + current.getHours()).slice(-2);
    const minutes = ('0' + current.getMinutes()).slice(-2);
    const seconds = ('0' + current.getSeconds()).slice(-2);

    return `${date}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleDeactivateSave = () => {
    const dateTime = getCurrentDateTime();
    deactivateAgent(token, userId, dateTime, agentDetails.id, comment)
      .then((response) => {
        toast.success('Agent Deactivated');
        getAgentDetails();
      })
      .catch((err) => toast.error('Failed to Deactivate Agent'));
  };

  useEffect(() => {
    getAgentDetails();
    if (!name && !publisher && !state && !version) {
      fetchAgentSoftwareDetails();
    }
    if (token && agentId) {
      fetchAppUsage();
    }
  }, []);

  useEffect(() => {
    if (!name && !publisher && !state && !version) {
      fetchAgentSoftwareDetails();
    }
  }, [name, publisher, state, version]);

  useEffect(() => {
    fetchHistoryList();
    fetchHistoryCount();
  }, []);

  useEffect(() => {
    if (!historyCount) {
      fetchHistoryList();
    }
  }, [historyCount]);

  let upTimeData = moment
    .unix(agentDetails.sessionUptime)
    .utc()
    .format('H [hours and] m [minutes] ');

  useEffect(() => {
    setFilter(false);
  }, [activeTab]);

  useEffect(() => {
    getAgentFunctionValues();
  }, []);

  useEffect(() => {
    if (validationList.length > 0) {
      getSoftwareValidated(token, validationList)
        .then((res) => {
          toast.success(res.data);
          fetchAgentSoftwareDetails();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [validationList]);

  console.log(validationList);

  return (
    <Container>
      <div>
        <div className="container-fluid">
          <div className="d-flex justify-content-end mt-3">
            <div className="mr-5">
              <div className="d-flex flex-wrap justify-content-end align-items-center">
                {activeTab === 'softwareDetails' &&
                  agentSoftwareDetails?.length !== 0 && (
                    <>
                      <NoOfRecords
                        value={numberOfRecords}
                        setValue={(value) => setNumberOfRecords(value)}
                        totalRecords={agentSoftwareDetails?.length}
                      />
                      <div className="paginationContainer">
                        <Pagination
                          className="m-1 mb-2"
                          currentPage={softwareDetailsPageNo}
                          recordsPerPage={numberOfRecords}
                          totalRecords={agentSoftwareDetails?.length}
                          onPageChange={(page: number) =>
                            setSoftwareDetailsPageNo(page)
                          }
                        />
                      </div>
                      <button
                        type="button"
                        className="m-1 filterButton mainFilterButton"
                        onClick={() => setFilter(!filter)}
                        aria-expanded={filter}
                        aria-controls="example-collapse-text"
                        id="me-softwaredetails-filter-btn"
                      >
                        <i className="fa-solid fa-filter" />
                        <span className="pl-1">Filter</span>
                      </button>
                    </>
                  )}

                {activeTab === 'appUsage' && appUsageList?.length !== 0 && (
                  <>
                    <NoOfRecords
                      value={appUsageNoOfRecords}
                      setValue={(value) => setAppUsageNoOfRecords(value)}
                      totalRecords={appUsageList?.length}
                    />
                    <div className="paginationContainer">
                      <Pagination
                        className="m-1 mb-2"
                        currentPage={appUsagePageNo}
                        recordsPerPage={appUsageNoOfRecords}
                        totalRecords={appUsageList?.length}
                        onPageChange={(page: number) => setAppUsagePageNo(page)}
                      />
                    </div>
                    {/* <button
                        type="button"
                        className="m-1 filterButton"
                        onClick={() => setFilter(!filter)}
                        aria-expanded={filter}
                        aria-controls="example-collapse-text"
                      >
                        <i className="fa-solid fa-filter" />
                        <span className="pl-1">Filter</span>
                      </button> */}
                  </>
                )}
                {activeTab === 'browserHistory' && HistoryList?.length !== 0 && (
                  <>
                    <NoOfRecords
                      value={historyNoOfRecords}
                      setValue={(value) => setHistoryNoOfRecords(value)}
                      totalRecords={HistoryList?.length}
                    />
                    <div className="paginationContainer">
                      <Pagination
                        className="m-1 mb-2"
                        currentPage={historyPageNo}
                        recordsPerPage={historyNoOfRecords}
                        totalRecords={HistoryList?.length}
                        onPageChange={(page: number) => setHistoryPageNo(page)}
                      />
                    </div>
                    {/* <button
                        type="button"
                        className="m-1 filterButton"
                        onClick={() => setFilter(!filter)}
                        aria-expanded={filter}
                        aria-controls="example-collapse-text"
                      >
                        <i className="fa-solid fa-filter" />
                        <span className="pl-1">Filter</span>
                      </button> */}
                  </>
                )}
                {activeTab === 'Service' && serviceList?.length !== 0 && (
                  <>
                    <NoOfRecords
                      value={serviceNoOfRecords}
                      setValue={(value) => setServiceNoOfRecords(value)}
                      totalRecords={serviceList?.length}
                    />
                    <div className="paginationContainer">
                      <Pagination
                        className="m-1 mb-2"
                        currentPage={servicePageNo}
                        recordsPerPage={serviceNoOfRecords}
                        totalRecords={serviceList?.length}
                        onPageChange={(page: number) => setServicePageNo(page)}
                      />
                    </div>
                    {/* <button
                        type="button"
                        className="m-1 filterButton"
                        onClick={() => setFilter(!filter)}
                        aria-expanded={filter}
                        aria-controls="example-collapse-text"
                      >
                        <i className="fa-solid fa-filter" />
                        <span className="pl-1">Filter</span>
                      </button> */}
                  </>
                )}
              </div>
            </div>
          </div>

          {filter && (
            <section
              className={`FilterOption ${
                darkmode && 'darkSecondaryWrapper'
              } d-flex flex-column mx-5 mb-0 mt-3`}
            >
              <Form className="d-flex flex-wrap justify-content-between">
                <Form.Group
                  controlId="role"
                  className="col-lg-3 col-md-3 col-12"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="search"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    id="me-details-name-option"
                  />
                </Form.Group>
                <Form.Group
                  controlId="role"
                  className="col-lg-3 col-md-3 col-12"
                >
                  <Form.Label>Publisher</Form.Label>
                  <Form.Control
                    type="search"
                    placeholder="Enter publisher"
                    value={publisher}
                    onChange={(e) => {
                      setPublisher(e.target.value);
                    }}
                    id="me-details-publisher-option"
                  />
                </Form.Group>
                <Form.Group
                  controlId="role"
                  className="col-lg-3 col-md-3 col-12"
                >
                  <Form.Label>State</Form.Label>
                  {/* <Form.Control
                    type="search"
                    placeholder="Enter state"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  /> */}
                  <Form.Control
                    as="select"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                    id="me-details-state-option"
                  >
                    <option value="">--Select--</option>
                    <option value="installed">Installed</option>
                    <option value="uninstalled">Uninstalled</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  controlId="role"
                  className="col-lg-3 col-md-3 col-12"
                >
                  <Form.Label>Version</Form.Label>
                  <Form.Control
                    type="search"
                    placeholder="Enter version"
                    value={version}
                    onChange={(e) => {
                      setVersion(e.target.value);
                    }}
                    id="me-details-version-option"
                  />
                  {/* <Form.Control
                    as="select"
                    // value={active}
                    // onChange={(e) => {
                    //   setActive(e.target.value);
                    //   // handleChange(e);
                    // }}
                  >
                    <option value="">--Select--</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Form.Control> */}
                </Form.Group>
              </Form>

              <div className="d-flex flex-wrap justify-content-center mt-4 pl-1">
                <button
                  type="button"
                  className="m-1 pointer alertFilterButton"
                  onClick={(e) => fetchAgentSoftwareDetails()}
                  id="me-app-details-apply-btn"
                >
                  Apply
                </button>
                <button
                  type="button"
                  className="m-1 pointer alertFilterButton"
                  onClick={() => {
                    setName('');
                    setPublisher('');
                    setState('');
                    setVersion('');
                  }}
                  id="me=app-details-clear-btn"
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="Close m-1 pointer alertFilterButton"
                  onClick={() => setFilter(false)}
                  id="me-app-details-close-btn"
                >
                  Close
                </button>
              </div>
            </section>
          )}
          {activeTab === 'browserHistory' && (
            <section
              className={`FilterOption ${
                darkmode && 'darkSecondaryWrapper'
              } d-flex flex-column mx-5 mb-0 mt-3`}
            >
              <Form className="d-flex flex-wrap justify-content-between">
                <Form.Group
                  controlId="role"
                  className="col-lg-3 col-md-3 col-12"
                >
                  <Form.Label>Browser Type</Form.Label>
                  <Form.Control
                    type="search"
                    placeholder="Enter name"
                    value={browserType}
                    onChange={(e) => {
                      setBrowserType(e.target.value);
                    }}
                    id="me-details-name-option"
                  />
                </Form.Group>
                <Form.Group
                  controlId="role"
                  className="col-lg-3 col-md-3 col-12"
                >
                  <Form.Label>Domain</Form.Label>
                  <Form.Control
                    type="search"
                    placeholder="Enter publisher"
                    value={domain}
                    onChange={(e) => {
                      setDomain(e.target.value);
                    }}
                    id="me-details-publisher-option"
                  />
                </Form.Group>
                <Form.Group
                  controlId="role"
                  className="col-xl-2 col-lg-4 col-sm-5 col-12 position-relative"
                >
                  <Form.Label>Start Date</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="date"
                      value={dateStart}
                      onChange={(e) => {
                        setDateStart(e.target.value);
                        setCalendarDateStart(new Date(e.target.value));
                      }}
                      id="alerts-start-date-option"
                    />
                    <span
                      className="customCalenderPickerIcon"
                      onClick={() => setShowDateStart((state) => !state)}
                      aria-hidden
                      id="alerts-start-calendar"
                    />
                  </div>
                  {showDateStart && (
                    <div
                      className="px-0 d-flex flex-shrink-0 position-absolute customDateRangeCalendar no-select"
                      style={{width: '308px'}}
                      ref={dateStartCalendarRef}
                    >
                      <div className="dateRangeCard w-100 d-flex flex-column align-items-center justify-content-between pb-0">
                        <Form.Control
                          as="select"
                          value={dateRange}
                          onChange={(e) => setDateRange(e.target.value)}
                        >
                          {/* <option value="">--Select--</option> */}
                          <option value="today">Today</option>
                          <option value="yesterday">Yestereday</option>
                          <option value="last3Days">Last 3 Days</option>
                          <option value="thisWeek">This Week</option>
                          <option value="thisMonth">This Month</option>
                        </Form.Control>
                        <div className="mt-3">
                          <Calendar
                            value={calendarDateStart}
                            onChange={(value: any) => {
                              setCalendarDateStart(value);
                              setDateStart(formatDatetimeToDate(value));
                              setShowDateStart(false);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Form.Group>
                <Form.Group
                  controlId="role"
                  className="col-xl-2 col-lg-4 col-sm-5 col-12"
                >
                  <Form.Label>End Date</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="date"
                      value={dateEnd}
                      onChange={(e) => {
                        setDateEnd(e.target.value);
                        setCalendarDateEnd(new Date(e.target.value));
                      }}
                      id="alerts-end-date-option"
                    />
                    <span
                      className="customCalenderPickerIcon"
                      onClick={() => setShowDateEnd((state) => !state)}
                      aria-hidden
                      id="alerts-end-calendar"
                    />
                  </div>
                  {showDateEnd && (
                    <div
                      className="px-0 d-flex flex-shrink-0 position-absolute customDateRangeCalendar no-select"
                      style={{width: '308px'}}
                      ref={dateEndCalendarRef}
                    >
                      <div className="dateRangeCard w-100 d-flex flex-column align-items-center justify-content-between pb-0">
                        <Form.Control
                          as="select"
                          value={dateRange}
                          onChange={(e) => setDateRange(e.target.value)}
                        >
                          {/* <option value="">--Select--</option> */}
                          <option value="today">Today</option>
                          <option value="yesterday">Yestereday</option>
                          <option value="last3Days">Last 3 Days</option>
                          <option value="thisWeek">This Week</option>
                          <option value="thisMonth">This Month</option>
                        </Form.Control>
                        <div className="mt-3">
                          <Calendar
                            value={calendarDateEnd}
                            onChange={(value: any) => {
                              setCalendarDateEnd(value);
                              setDateEnd(formatDatetimeToDate(value));
                              setShowDateEnd(false);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {dateStart && !dateEnd ? (
                    <p className="text-danger pl-3">Enter End Date</p>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </Form>

              <div className="d-flex flex-wrap justify-content-center mt-4 pl-1">
                <button
                  type="button"
                  className="m-1 pointer alertFilterButton"
                  onClick={(e) => fetchHistoryList()}
                  id="me-browse-details-apply-btn"
                >
                  Apply
                </button>
                <button
                  type="button"
                  className="m-1 pointer alertFilterButton"
                  onClick={() => {
                    setBrowserType('');
                    setDomain('');
                    setDateRange('');
                    setDateStart('');
                    setCalendarDateStart('');
                    fetchHistoryList(false)
                  }}
                  id="me-browse-details-clear-btn"
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="Close m-1 pointer alertFilterButton"
                  onClick={() => setFilter(false)}
                  id="me-browse-details-close-btn"
                >
                  Close
                </button>
              </div>
            </section>
          )}

          <div className="overflow-auto ">
            <div
              className={`agentDetailsTable mx-5 ${
                darkmode && 'darkSecondaryWrapper'
              }`}
            >
              <ul className="tabs-nav">
                <li
                  className={`tab ${activeTab === 'agentInfo' ? 'active' : ''}`}
                  onClick={() => handleTabClick('agentInfo')}
                  aria-hidden
                  id="me-info"
                >
                  Managed Endpoint Info
                </li>
                <li
                  className={`tab ${
                    activeTab === 'hardwareInfo' ? 'active' : ''
                  }`}
                  onClick={() => handleTabClick('hardwareInfo')}
                  aria-hidden
                  id="me-hardware-info"
                >
                  Hardware Info
                </li>
                <li
                  className={`tab ${activeTab === 'OSInfo' ? 'active' : ''}`}
                  onClick={() => handleTabClick('OSInfo')}
                  aria-hidden
                  id="me-os-info"
                >
                  OS Info
                </li>
                <li
                  className={`tab ${
                    activeTab === 'sessionDetails' ? 'active' : ''
                  }`}
                  onClick={() => handleTabClick('sessionDetails')}
                  aria-hidden
                  id="me-session-details-info"
                >
                  Session Details
                </li>
                <li
                  className={`tab ${
                    activeTab === 'network&Ports' ? 'active' : ''
                  }`}
                  onClick={() => handleTabClick('network&Ports')}
                  aria-hidden
                  id="me-network-ports-info"
                >
                  Network and Ports
                </li>
                <li
                  className={`tab ${
                    activeTab === 'softwareDetails' ? 'active' : ''
                  }`}
                  onClick={() => handleTabClick('softwareDetails')}
                  aria-hidden
                  id="me-software-details-info"
                >
                  Software Details
                </li>
                <li
                  className={`tab ${
                    activeTab === 'browserHistory' ? 'active' : ''
                  }`}
                  onClick={() => handleTabClick('browserHistory')}
                  aria-hidden
                  id="me-browser-history-info"
                >
                  Browser History
                </li>
                <li
                  className={`tab ${activeTab === 'appUsage' ? 'active' : ''}`}
                  onClick={() => handleTabClick('appUsage')}
                  aria-hidden
                  id="me-app-usage-info"
                >
                  App Usage
                </li>
                <li
                  className={`tab ${activeTab === 'Service' ? 'active' : ''}`}
                  onClick={() => handleTabClick('Service')}
                  aria-hidden
                  id="me-service-info"
                >
                  Services
                </li>
              </ul>

              <div className="tab-content">
                <div className="tab-table-container">
                  {/* Agent Info Table */}
                  {activeTab === 'agentInfo' && (
                    <div className="agentInfo">
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey mt-2">
                          <span>Managed Endpoint Alias</span>
                        </div>
                        <div className="tableValue">
                          <div className="input-container">
                            <Form.Control
                              placeholder="ALIAS NAME"
                              className="custom-input"
                              disabled={!isAdmin || loading}
                              value={aliasName}
                              onChange={(e) => setAliasName(e.target.value)}
                              id="me-alias-name"
                            />
                            <div
                              className="tick-box"
                              onClick={() => updateAliasName()}
                              aria-hidden
                              id="me-alias-tick-box"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey mt-0">
                          <span>Function</span>
                        </div>
                        <div className="tableValue">
                          <div className="input-container">
                            <Form.Control
                              as="select"
                              className="custom-input"
                              disabled={!isAdmin || loading}
                              value={functionValue}
                              onChange={(e) => setFunctionValue(e.target.value)}
                              style={{width: '205px'}}
                              id="me-function-field"
                            >
                              <option>--SELECT--</option>
                              {functionRoles?.map((i: any, index: number) => (
                                <option key={index} value={i.key}>
                                  {i.value}
                                </option>
                              ))}
                            </Form.Control>
                            <div
                              className="tick-box"
                              onClick={() => updateFunctionValue()}
                              aria-hidden
                              id="me-function-tick-box"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Endpoint ID</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.id}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Agent Version</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.version}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span> Last Sync</span>
                        </div>
                        <div className="tableValue">
                          <span>{formatDate(agentDetails.lastSync)}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Latitude</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.latitude}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Longitude</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.longitude}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>City</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.city}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Time Zone</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.timezone}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span> Session Elevated</span>
                        </div>
                        <div className="tableValue">
                          {agentDetails.sessionElevated ? (
                            <i className="fa-regular fa-circle-check" />
                          ) : (
                            <i className=" fa-sharp fa-regular fa-circle-xmark" />
                          )}
                        </div>
                      </div>
                      {isAdmin && (
                        <div className="d-flex">
                          <div className="col-3 borderRight tableKey">
                            <span>Deactivate</span>
                          </div>
                          <div className="tableValue">
                            {/* {agentDetails.timezone} */}
                            <Form.Group
                              className=" activeLabel d-flex"
                              controlId=""
                            >
                              {/* <Form.Check
                                type="checkbox"
                                label=""
                                className=""
                                // checked={}
                                // onChange={(e) => setActive(e.target.checked)}
                                // disabled={
                                //   !isAdmin ||
                                //   selectedUser?.userId === currentUser?.userId
                                // }
                              /> */}
                              <input
                                type="checkbox"
                                name="deactivate"
                                id="deactivate"
                                className="form-check-input"
                                checked={deactivate}
                                disabled={!agentDetails.active}
                                onChange={(e) =>
                                  setDeactivate(e.target.checked)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="deactivate"
                                id="me-check-label"
                              />
                              <textarea
                                name=""
                                id="me-deactivate-text"
                                cols={30}
                                rows={3}
                                value={comment}
                                disabled={!agentDetails.active || !deactivate}
                                onChange={(e) => setComment(e.target.value)}
                              />
                              <div>
                                <button
                                  type="button"
                                  className="filterButton ml-3"
                                  onClick={() => handleDeactivateSave()}
                                  disabled={!agentDetails.active || !deactivate}
                                  id="me-browse-save-btn"
                                >
                                  <span className="pl-1">Save</span>
                                </button>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      )}
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey" />
                        <div className="tableValue" />
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey" />
                        <div className="tableValue" />
                      </div>
                    </div>
                  )}
                  {/* Hardware Info Table */}
                  {activeTab === 'hardwareInfo' && (
                    <div className="hardwareInfo">
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Name</span>
                        </div>
                        <div className="tableValue">
                          <span> {agentDetails.hwCpuName}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>CPU Count</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.hwCpuCount}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Frequency</span>
                        </div>
                        <div className="tableValue">
                          <span> {agentDetails.hwCpuFreq}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Manufacturer</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.hwManufacturer}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Bios</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.hwBios}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Model</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.hwModel}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>RAM</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.hwRam}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>UUID</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.hwUuid}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey" />
                        <div className="tableValue" />
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey" />
                        <div className="tableValue" />
                      </div>
                    </div>
                  )}
                  {/* OS Info Table */}
                  {activeTab === 'OSInfo' && (
                    <div className="OSInfo">
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Family</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.osFamily}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Manufacturer</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.osManufacturer}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Version</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.osVersion} {agentDetails.osDisplayVersion}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Build</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.osBuild}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Bit Version</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.osBit}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey" />
                        <div className="tableValue" />
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey" />
                        <div className="tableValue" />
                      </div>
                    </div>
                  )}
                  {/* Session Details Table */}
                  {activeTab === 'sessionDetails' && (
                    <div className="sessionDetails">
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Username</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.sessionUsername}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Uptime</span>
                        </div>
                        <div className="tableValue">
                          {/* {`${upTimeConvert[0]} hours, ${upTimeConvert[1]} minutes`} */}
                          <span>{upTimeData}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Process Count</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.sessionProcessCount}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Boot Time</span>
                        </div>
                        <div className="tableValue">
                          <span>
                            {formatDate(agentDetails.sessionBootTime)}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Login</span>
                        </div>
                        <div className="tableValue">
                          <span>{formatDate(agentDetails.sessionLogin)}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Terminal</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.sessionTerminal}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey" />
                        <div className="tableValue" />
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey" />
                        <div className="tableValue" />
                      </div>
                    </div>
                  )}
                  {/* Network & Ports Table */}
                  {activeTab === 'network&Ports' && (
                    <div className="network&Ports">
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Listening Port List</span>
                        </div>
                        <div className="col-5 tableValue">
                          {agentDetails.portList !== null
                            ? agentDetails.portList
                                ?.split(',')
                                .map((element: any, index: number) => (
                                  <span key={element}>
                                    {index > 0 ? ', ' : ''}
                                    {element}
                                  </span>
                                ))
                            : ''}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>DNS List</span>
                        </div>
                        <div className="col-5 tableValue">
                          {agentDetails.dnsList !== null
                            ? agentDetails.dnsList
                                ?.split(',')
                                .map((element: any) => (
                                  <span key={element}>{element} </span>
                                ))
                            : ''}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Hostname</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.hostname}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Domain</span>
                        </div>
                        <div className="tableValue">
                          <span>{agentDetails.domain}</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>MAC Address</span>
                        </div>
                        <div className="col-5 tableValue">
                          {agentDetails.macList !== null
                            ? agentDetails.macList
                                ?.split(',')
                                .map((element: any) => (
                                  <span key={element}>{element} </span>
                                ))
                            : ''}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>IPv4 List</span>
                        </div>
                        <div className="col-5 tableValue">
                          {agentDetails.ipv4List !== null
                            ? agentDetails.ipv4List
                                ?.split(',')
                                .map((element: any) => (
                                  <span key={element}>{element} </span>
                                ))
                            : ''}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>IPv6 List</span>
                        </div>
                        <div className="col-5 tableValue">
                          {agentDetails.ipv6List !== null
                            ? agentDetails.ipv6List
                                ?.split(',')
                                .map((element: any) => (
                                  <span key={element}>{element} </span>
                                ))
                            : ''}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey">
                          <span>Adapter</span>
                        </div>
                        <div className="col-5 tableValue">
                          {agentDetails.adapters !== null
                            ? agentDetails.adapters
                                ?.split(',')
                                .map((element: any) => (
                                  <span key={element}>{element} </span>
                                ))
                            : ''}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey" />
                        <div className="tableValue" />
                      </div>
                      <div className="d-flex">
                        <div className="col-3 borderRight tableKey" />
                        <div className="tableValue" />
                      </div>
                    </div>
                  )}
                  {/* Software Details Table */}
                  {activeTab === 'softwareDetails' && (
                    <div className="softwareDetails">
                      {agentSoftwareDetails?.length === 0 ? (
                        <div
                          className={` ${
                            darkmode ? 'noRecordsDark' : 'noRecords'
                          }`}
                        >
                          No Softwares Found
                        </div>
                      ) : (
                        <table
                          className={`w-100 customTable   ${
                            darkmode
                              ? 'darkTable darkDetailsCustomTable'
                              : ' lighttable'
                          }`}
                        >
                          <thead>
                            <tr className="">
                              {/* <th>Id</th> */}
                              <th
                                className="col-3 text-left py-3 pl-4"
                                onClick={() => {
                                  // handleTableHeaderSorting('userId')
                                  sortTableData('id');
                                }}
                              >
                                Name
                                {sortColumn === 'id' &&
                                  (sortOrder === 'asc' ? (
                                    <i className="fa fa-sort-up" />
                                  ) : (
                                    <i className="fa fa-sort-down" />
                                  ))}
                              </th>
                              <th
                                className="col-1 text-left pl-4"
                                onClick={() => {
                                  // handleTableHeaderSorting('userId')
                                  sortTableData('version');
                                }}
                              >
                                Version
                                {sortColumn === 'version' &&
                                  (sortOrder === 'asc' ? (
                                    <i className="fa fa-sort-up" />
                                  ) : (
                                    <i className="fa fa-sort-down" />
                                  ))}
                              </th>
                              <th
                                className="col-1 text-left pl-4"
                                onClick={() => {
                                  // handleTableHeaderSorting('userId')
                                  sortTableData('publisher');
                                }}
                              >
                                Publisher
                                {sortColumn === 'publisher' &&
                                  (sortOrder === 'asc' ? (
                                    <i className="fa fa-sort-up" />
                                  ) : (
                                    <i className="fa fa-sort-down" />
                                  ))}
                              </th>
                              <th
                                className="col-1"
                                onClick={() => {
                                  // handleTableHeaderSorting('userId')
                                  sortTableData('installed');
                                }}
                              >
                                State
                                {sortColumn === 'installed' &&
                                  (sortOrder === 'asc' ? (
                                    <i className="fa fa-sort-up" />
                                  ) : (
                                    <i className="fa fa-sort-down" />
                                  ))}
                              </th>
                              <th
                                className="col-1"
                                onClick={() => {
                                  // handleTableHeaderSorting('userId')
                                  sortTableData('installedAt');
                                }}
                              >
                                Date
                                {sortColumn === 'installedAt' &&
                                  (sortOrder === 'asc' ? (
                                    <i className="fa fa-sort-up" />
                                  ) : (
                                    <i className="fa fa-sort-down" />
                                  ))}
                              </th>
                              <th
                                className="col-1 text-left pl-4"
                                onClick={() => {
                                  // handleTableHeaderSorting('userId')
                                  sortTableData('size');
                                }}
                              >
                                Size
                                {sortColumn === 'size' &&
                                  (sortOrder === 'asc' ? (
                                    <i className="fa fa-sort-up" />
                                  ) : (
                                    <i className="fa fa-sort-down" />
                                  ))}
                              </th>

                              <th
                                className="col-1 text-center pl-2"
                                onClick={() => {
                                  // handleTableHeaderSorting('userId')
                                  sortTableData('validated');
                                }}
                              >
                                Validated
                                {sortColumn === 'validated' &&
                                  (sortOrder === 'asc' ? (
                                    <i className="fa fa-sort-up" />
                                  ) : (
                                    <i className="fa fa-sort-down" />
                                  ))}
                              </th>
                              {isAdmin === 'ADMIN' && (
                                <th className="col-2 text-center pl-2">
                                  Mark as validated
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody className="mb-3">
                            {softwarePaginatedData?.map((data: any) => (
                              <tr key={data.id}>
                                {/* <td>{data.id}</td> */}
                                <td className="col-3 text-left pl-4">
                                  {data.name}
                                </td>
                                <td className="col-1 text-left pl-4">
                                  {data.version}
                                </td>
                                <td className="col-2 text-left pl-4">
                                  {data.publisher}
                                </td>
                                <td className="col-1">
                                  {data.installed ? 'Installed' : 'Uninstalled'}
                                </td>
                                <td className="col-1">
                                  {formatDate(data.installedAt)}
                                </td>
                                <td className="col-1 text-left pl-4">
                                  {data.size
                                    ? formatBytes(data.size)
                                    : data.size}
                                </td>

                                <td className="col-1 text-center pl-4">
                                  {data.validated ? (
                                    <i className="fa-regular fa-circle-check" />
                                  ) : (
                                    <i className=" fa-sharp fa-regular fa-circle-xmark" />
                                  )}
                                </td>
                                {isAdmin === 'ADMIN' && (
                                  <td className="col-2 ">
                                    {data?.validated ? (
                                      <input
                                        type="checkbox"
                                        className="cursor-pointer"
                                        checked={data?.validated}
                                        onChange={(e) => {
                                          // setValidationList([data?.id])
                                        }}
                                        name=""
                                        id="me-mark-validated-check"
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        className="cursor-pointer"
                                        onChange={(e) => {
                                          setValidationList([data?.id]);
                                        }}
                                        name=""
                                        id="me-mark-validated-empty"
                                      />
                                    )}
                                  </td>
                                )}
                              </tr>
                            ))}
                            {/* {agentSoftwareDetails?.length === 0 && (
                            <tr>
                              <td colSpan={5}>No Softwares Found</td>
                            </tr>
                          )} */}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}

                  {activeTab === 'appUsage' && (
                    <AppUsageTable
                      agentDetails={agentDetails}
                      appUsagePaginatedData={appUsagePaginatedData}
                      appUsageList={appUsageList}
                      setAppUsageList={setAppUsageList}
                    />
                  )}
                  {activeTab === 'Service' && <AgentService  servicePaginatedData={servicePaginatedData} setServiceList={setServiceList}/>}
                  {activeTab === 'browserHistory' && (
                    <BrowserHistory
                      HistoryList={HistoryList}
                      setHistoryList={setHistoryList}
                      historyPaginatedData={historyPaginatedData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mx-5">
            <button
              className="filterButton px-5 mt-3"
              onClick={() => {
                navigate(-1);
              }}
              id="me-details-back-btn"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AgentDetails;
