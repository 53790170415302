import {getForgotUsername, resetPassword} from '@app/services/swaggerAuth';
import { ValidationResult, getStrengthColor, validatePassword } from '@app/utils/helpers';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {Col, Form, InputGroup, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

type Props = {};

function Reset({}: Props) {
  const {param} = useParams();

  const [email, setemail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  const [validation, setValidation] = useState<ValidationResult | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const handlePasswordFocus = () => {
    setPasswordFocused(true);
    setHasStartedTyping(true); 
  };

  const handlePasswordBlur = (e: any) => {
   
    setPasswordFocused(false);
  };



  useEffect(() => {
    getForgotUsername(param || '')
      .then((res) => {
        setemail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [param]);

  const handlePasswordConfirm = () => {
    if (newPassword !== confirmPassword) {
      setPasswordError(true);
    }

    resetPassword(param || '', newPassword)
      .then((res: any) => {
        toast.success("reset success full ")
        setTimeout(() => {
            window.location.href = '/login';
        }, 2000);
      })
      .catch((error) => {
        alert('Failed to reset password');
      });
  };

  useEffect(() => {
    if (showPassword) {
      setTimeout(() => {
        setShowPassword(false);
      }, 3000);
    }
  }, [showPassword]);
  useEffect(() => {
    if (showConfirmPassword) {
      setTimeout(() => {
        setShowConfirmPassword(false);
      }, 3000);
    }
  }, [showConfirmPassword]);


  return (
    <div className="login-root container d-flex align-items-center justify-content-center">
      <Row className="login-container">
        <Col
          className="left-side d-flex align-items-center justify-content-center"
          xs={12}
          md={6}
        >
          <img
            className="logoImg"
            src="/img/forgot_password_icon.png"
            alt=""
            style={{width: '180px'}}
          />
        </Col>
        <Col className="right-side">
          <div className="wrapper-container p-">
            <div className="mt-5">
              <h3>Reset</h3>
              <h3>your password</h3>
            </div>

            <form className="form-container mt-5" >
              <div className="mt-3">
                <Form.Label>Email</Form.Label>
                <div className="mb-3">
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="email"
                      name="email"
                      type="email"
                      disabled
                      placeholder="username@cyberzek.com"
                      value={email}
                    />
                   
                  </InputGroup>
                </div>
              </div>
              <div className="mt-3">
                <Form.Label>New Password</Form.Label>
                <div className="mb-3">
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        setValidation(validatePassword(e.target.value));
                        if (!hasStartedTyping) {
                          setHasStartedTyping(true);
                        }
                      }}
                      onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                      value={newPassword}
                    />
                   <InputGroup.Append>
                        <InputGroup.Text>
                          {/* <i className="fas fa-lock" /> */}
                          <i
                            className={`fa ${
                              showPassword ? 'fa-eye' : 'fa-eye-slash'
                            }`}
                            onClick={() => setShowPassword((show) => !show)}
                            aria-hidden="true"
                          />
                        </InputGroup.Text>
                      </InputGroup.Append>
                  </InputGroup>
                </div>
              </div>

              {(hasStartedTyping && passwordFocused) && (
  <>

<div className="password-strength-bar">
        <div
          className="password-strength-indicator"
          style={{
            width: `${(validation?.score || 0) / 8 * 100}%`,
            backgroundColor: getStrengthColor(validation?.score || 0)
          }}
        ></div>
      </div>
      <div>
        <p>Password Strength:</p>
        <ul>
          <li style={{ color: validation?.minLength ? 'green' : 'red' }}>
            Minimum 8 characters
          </li>
          <li style={{ color: validation?.hasUpperCase ? 'green' : 'red' }}>
            Includes uppercase letters
          </li>
          <li style={{ color: validation?.hasLowerCase ? 'green' : 'red' }}>
            Includes lowercase letters
          </li>
          <li style={{ color: validation?.hasNumber ? 'green' : 'red' }}>
            Includes numbers
          </li>
          <li style={{ color: validation?.hasSpecialChar ? 'green' : 'red' }}>
            Includes special characters
          </li>
          <li style={{ color: validation?.noSequentialChars ? 'green' : 'red' }}>
            No sequential characters
          </li>
          <li style={{ color: validation?.noRepetitivePatterns ? 'green' : 'red' }}>
            No repetitive patterns
          </li>
          <li style={{ color: validation?.notCommonPassword ? 'green' : 'red' }}>
            Not a common password
          </li>
        </ul>
      </div>
      </>)}



              <div className="mt-3">
                <Form.Label>Confirm Password</Form.Label>
                <div className="mb-3">
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      value={confirmPassword}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text>
                          {/* <i className="fas fa-lock" /> */}
                          <i
                            className={`fa ${
                              showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'
                            }`}
                            onClick={() => setShowConfirmPassword((show) => !show)}
                            aria-hidden="true"
                          />
                        </InputGroup.Text>
                      </InputGroup.Append>
                   
                  </InputGroup>
                </div>
              </div>

              {passwordError && (
                <small className="text-danger">password does not match</small>
              )}

              <div className="mt-4">
                <button
                  className="filterButton w-100"
                  type="submit"
                  onClick={handlePasswordConfirm}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Reset;
