import Container from '@app/components/CustomDash/Container';
import { getVulnerabilityCveId } from '@app/services/threats';
import { formatDate } from '@app/utils/helpers';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {}

function CvidDeatils({}: Props) {

    const [cveData, setcveData] = useState()

    const darkmode = useSelector((state: any) => state.account.darkmode);
    const token = useSelector((state: any) => state.auth.token);
    const {cvid} = useParams();

    const navigate = useNavigate();



        useEffect(() => {
            getVulnerabilityCveId(cvid||"",token).then((res) => {
                setcveData(res.data)
            }).catch((err) => {
                console.log(err)
            })
        }, [cvid])
        


  return (
    <Container>

    
    <div className="container-fluid">
    <div className="d-flex justify-content-between">
      <h3 className="subHeaders mx-5 "> </h3>
    </div>
    <div className="overflow-auto ">
          <div
            className={`customTable-2 VulnerabilityDetails mx-5 ${
              darkmode && 'darkSecondaryWrapper'
            }`}
          >
 <table
              className={`w-100 vulnerabilityDEtailsTav ${
                darkmode ? 'darkTable' : ''
              }`}
            >
              <thead>
                <tr>
                  <th className="col-3"></th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="">
                <tr>
                  <td> ID</td>
                  <td>{cveData?.id}</td>
                </tr>
                <tr>
                  <td> CVE Id</td>
                  <td>{cvid}</td>
                </tr>
                <tr>
                  <td> Advisories</td>
                  <td>{cveData?.advisories}</td>
                </tr>
                <tr>
                  <td> Vulnstatus</td>
                  <td>{cveData?.vulnstatus}</td>
                </tr>
                <tr>
                  <td>Updated At</td>
                  <td>{formatDate(cveData?.updatedAt)}</td>
                </tr>
                <tr>
                  <td>published</td>
                  <td>{formatDate(cveData?.published)}</td>
                </tr>
                <tr>
                  <td>last modified</td>
                  <td>{formatDate(cveData?.lastmodified)}</td>
                </tr>
                <tr>
                  <td> Source</td>
                  <td>{cveData?.source}</td>
                </tr>
                <tr>
                  <td> Version</td>
                  <td>{cveData?.version}</td>
                </tr>
                <tr>
                  <td> Version</td>
                  <td>{cveData?.version}</td>
                </tr>
                <tr>
                  <td> Base score</td>
                  <td>{cveData?.basescore}</td>
                </tr>
                <tr>
                  <td> Base Severity</td>
                  <td>{cveData?.baseseverity}</td>
                </tr>
                <tr>
                  <td> software Name</td>
                  <td>{cveData?.softwareName}</td>
                </tr>
                <tr>
                  <td> Description</td>
                  <td>{cveData?.description}</td>
                </tr>
               
              </tbody>
            </table>


          </div>
          <button
                      className="filterButton px-5 ml-5"
                      onClick={() => {
                        navigate(-1);
                      }}
                      id="vul-details-back-btn"
                    >
                      Back
                    </button>
          </div>
          </div>
          </Container>
  )
}

export default CvidDeatils