import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
//import Register from '@modules/register/Register';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import {useWindowSize} from '@app/hooks/useWindowSize';
import {calculateWindowSize} from '@app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {setWindowSize} from '@app/store/reducers/ui';

// import Dashboard from '@pages/Dashboard';
// import Blank from '@pages/Blank';
// import SubMenu from '@pages/SubMenu';
// import Profile from '@pages/profile/Profile';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import PageOneForms from './pages/dashbords/PageOneForms';
import Users from './pages/users/Users';
import Agents from './pages/agents/Agents';
import Profile from './pages/profile/Profile';
import Dashboard from './pages/dashbords/Dashboard';
import AgentDetails from './pages/agents/AgentDetails';
import './App.css';
import 'leaflet/dist/leaflet.css';
import Settings from './pages/Settings';
import PageNotFound from './pages/PageNotFound';
import Alert from './pages/alerts/Alert';
import AlertDetailsPage from './pages/alerts/AlertDetailsPage';
import TrustedSoftware from './pages/trustedSoftware/TrustedSoftware';
import Threats from './pages/threats/Threats';
import AgentReports from './pages/reports/AgentReports';
import SoftwareInventoryReports from './pages/reports/SoftwareInventoryReports';
import UnacknowledgedAlerts from './pages/reports/UnacknowledgedAlerts';
import ConfigureIP from './pages/configure-ip/ConfigureIP';
import Reports from './pages/reports/Reports';
import ConfigureDebug from './pages/ConfigureDebug';
import Productivity from './pages/productivity/Productivity';
import ProductivitySubTabs from './pages/productivity/ProductivitySubTabs';
import RoleBasedAccessControl from './pages/role-based-access-control/RoleBasedAccessControl';
import RoleBasedAccessControlDetails from './pages/role-based-access-control/RoleBasedAccessControlDetails';
import {getEntitlement} from './services/account';
import {
  setDarkMode,
  setInsights,
  setProductivity,
  setRBAC,
  setThreats,
  setTrustedSoftware
} from '@app/store/reducers/Account';
import SubscriptionNotFound from './pages/SubscriptionNotFound';
import VulnerabilityDetails from './pages/threats/VulnerabilityDetails';
import AcknowledgedAlerts from './pages/reports/AcknowledgedAlerts';
import UnackVulnerabilityReports from './pages/reports/UnackVulnerabilityReports';
import AckVulnerabilityReports from './pages/reports/AckVulnerabilityReports';
// import Diagram from './pages/Diagram';
import ServiceReport from './pages/reports/ServiceReport';
import UniqueVulnerability from './pages/reports/UniqueVulnerability';
import PortsReport from './pages/reports/PortsReport';
import Reset from './pages/reset/Reset';
import AlertConfig from './pages/AlertConfig';
import CvidDeatils from './pages/threats/cvidDeatils';

const App = () => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const admin = localStorage.getItem('role');

  const token = useSelector((state: any) => state.auth.token);


  const {insight, productivity, rbac, threats, trustedSoftware} = useSelector(
    (state: any) => state.account
  );

  useEffect(() => {
    let mode = localStorage.getItem('darkmode');

    if (mode === 'true') {
      dispatch(setDarkMode(true));
    } else {
      dispatch(setDarkMode(false));
    }
  }, []);

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);

  useEffect(() => {
    getEntitlement(token)
      .then((res) => {
        let data = res.data;
        dispatch(setInsights(data.featureInsight));
        dispatch(setProductivity(data.featureProductivity));
        dispatch(setRBAC(data.featureRBAC));
        dispatch(setThreats(data.featureThreats));
        dispatch(setTrustedSoftware(data.featureTrustedSoftware));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/forgot-password" element={<PublicRoute />}>
          <Route path="/forgot-password" element={<ForgetPassword />} />
        </Route>
        <Route path="/recover-password" element={<PublicRoute />}>
          <Route path="/recover-password" element={<RecoverPassword />} />
        </Route>
        <Route path="/reset/:param" element={<PublicRoute />}>
          <Route path="/reset/:param" element={<Reset />} />
        </Route>
        
        <Route path="/" element={<PrivateRoute />}>
          {/* <Route path="/" element={<Main />}>
            <Route path="/sub-menu-2" element={<Blank />} />
            <Route path="/sub-menu-1" element={<SubMenu />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/change-password" element={<ChangePassword />} />x
            <Route path="/blank2" element={<Users />} />
          </Route> */}
          {/* <Route path="/maps" element={<Maps />} /> */}
          <Route path="/" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          {admin === 'ADMIN' && (
          <Route path="/users" element={<Users />} />
          )}
          {/* <Route
            path="/role-based-access-control"
            element={
              !rbac ?'' : <RoleBasedAccessControl />
            }
          />
          <Route
            path="/role-based-access-control-details/:rbacId"
            element={
              !rbac ? '' : (
                <RoleBasedAccessControlDetails />
              )
            }
          /> */}
          <Route path="/agents" element={<Agents />} />
          <Route
            path="/agents/agent-details/:agentId"
            element={<AgentDetails />}
          />
          <Route path="/vulnerability" element={!threats ? '' : <Threats />} />
          <Route
            path="/vulnerability/vulnerability-details/:id"
            element={!threats ? '' : <VulnerabilityDetails />}
          />
          <Route
            path="/vulnerability/vulnerability-cveid/:cvid"
            element={!threats ? '' : <CvidDeatils />}
          />
          <Route path="/alerts" element={<Alert />} />
          <Route
            path="/productivity"
            element={!productivity ? '' : <Productivity />}
          />
          <Route
            path="/productivity-details"
            element={<ProductivitySubTabs />}
          />
          <Route
            path="/trusted-software"
            element={!trustedSoftware ? '' : <TrustedSoftware />}
          />
          <Route
            path="/alerts/alert-details/:alertId"
            element={<AlertDetailsPage />}
          />
          <Route path="/pageonefroms" element={<PageOneForms />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/reports/agent-reports" element={<AgentReports />} />
          <Route
            path="/reports/software-inventory-reports"
            element={<SoftwareInventoryReports />}
          />
          <Route
            path="/reports/unacknowledged-alerts-reports"
            element={<UnacknowledgedAlerts />}
          />
          <Route
            path="/reports/unique-vulnerability-report"
            element={<UniqueVulnerability />}
          />
          <Route
            path="/reports/open-ports-report"
            element={<PortsReport />}
          />
          <Route
            path="/reports/acknowledged-alerts-reports"
            element={<AcknowledgedAlerts />}
          />
          <Route
            path="/reports/unacknowledged-vulnerability-reports"
            element={<UnackVulnerabilityReports />}
          />
          <Route
            path="/reports/acknowledged-vulnerability-reports"
            element={<AckVulnerabilityReports />}
          />
          <Route
            path="/reports/service-report"
            element={<ServiceReport />}
          />
          {admin === 'ADMIN' && (
            <>
            <Route path="/settings" element={<Settings />} />
            <Route path="/settings/alert-config" element={<AlertConfig />} />
            </>
          )}
          {/* <Route path="/insights" element={!insight ? <SubscriptionNotFound />:<LicensesNFeatures />} /> */}
          <Route path="/configure-ip-address" element={<ConfigureIP />} />
          <Route path="/configure-debug" element={<ConfigureDebug />} />
          {/* <Route path="/diagram-node" element={<Diagram />} /> */}
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </BrowserRouter>
  );
};

export default App;
