import Container from '@app/components/CustomDash/Container';
import {
  getAlertTypes,
  getUnacknowledgedAlerts,
  getUnacknowledgedAlertsDownlaod,
  getVulnerabilityDownlaod
} from '@app/services/alerts';
import {getVulnerabilityCount, getVulnerabilityList} from '@app/services/threats';
import {formatDatetimeToDate, handleInputChange} from '@app/utils/helpers';
import React, {useEffect, useRef, useState} from 'react';
import {Col, Form} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import Pagination from '@app/components/common/Pagination';
import NoOfRecords from '@app/components/common/NoOfRecords';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';

function AckVulnerabilityReports() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState('auto');
  const [listingData, setListingData] = useState([]);
  const [vulnerabilityPageNumber, setVulnerabilityPageNumber] = useState(1);

  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);

  const token = useSelector((state: any) => state.auth.token);
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  // Perform array slicing based on the current page
  const startIndex = (vulnerabilityPageNumber - 1) * numberOfRecords;
  const endIndex = vulnerabilityPageNumber * numberOfRecords;
  const vulnerabilityPaginatedData = listingData?.slice(startIndex, endIndex);

  const [count, setVulnerabilityCount] = useState(0);

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  // TODO FIX BUGS
  const sortTableData = (column: any) => {
    const sortedData = [...listingData];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      if (sortOrder === 'asc' || sortOrder === null) {
        // sort aesc if the current order is asc or null
        sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
        setSortColumn(column);
        setSortOrder('asc');
      } else {
        sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
        setSortColumn(column);
        setSortOrder('desc');
      }
    }

    setListingData(sortedData);
  };


  const getVulnarabilityCount = async () => {
    getVulnerabilityCount(token)
      .then((res) => {
        setVulnerabilityCount(res.data);
      })
      .catch((err) => {console.log(err)});
  };

  useEffect(() => {
    
    getVulnarabilityCount()
   
  }, [])
  


  useEffect(() => {
    const fetchVulnerabilityModuleList = () => {
      getVulnerabilityList(token,count,'','','',true,'','','')
        .then((response) => {
          const {data} = response;
          setListingData(data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    };

    fetchVulnerabilityModuleList();
  }, [count]);

  useEffect(() => {
    if (vulnerabilityPaginatedData?.length === 0) {
      setVulnerabilityPageNumber(1);
    }
  }, [vulnerabilityPaginatedData]);

  const downloadCSV = () => {
    getVulnerabilityDownlaod(token,true,true)
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Acknowledged Vulnerability Reports.csv'; // Replace with the desired filename and extension
        a.click();

        // Release the temporary URL
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error('Failed to download Software Inventory Reports');
      });
  };

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight; // Get the height of the content
      console.log(contentHeight)
      setHeight(contentHeight + 400 + 'px'); // Add 400px to the content height
    }
  }, [contentRef]);
  return (
    <Container>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center align-items-center">
          <Col xs={12}>
            <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
              <h3 className="subHeaders"> </h3>
              <div className="d-flex  flex-wrap align-items-center justify-content-end">
                <NoOfRecords
                  value={numberOfRecords}
                  setValue={(value) => setNumberOfRecords(value)}
                  totalRecords={listingData?.length}
                />

                {listingData?.length !== 0 && (
                  <div className="paginationContainer">
                    <Pagination
                      className="mr-1"
                      currentPage={vulnerabilityPageNumber}
                      recordsPerPage={numberOfRecords}
                      totalRecords={listingData?.length}
                      onPageChange={(page: number) =>
                        setVulnerabilityPageNumber(page)
                      }
                    />
                  </div>
                )}
                <button
                  className="reportsBackButton  mr-1"
                  onClick={() => {
                    {
                      navigate('/reports');
                    }
                  }}
                  id='a-v-reports-back-btn'
                >
                    <i className="fa-solid fa-left-long"></i>
                  <span>Back</span>
                </button>

                <button
                  type="button"
                  onClick={downloadCSV}
                  className="m-1 filterButton mainFilterButton"
                  id='a-v-reports-download-btn'
                >
                  <i className="fa-solid fa-download"></i>
                  <span className="ml-2">Reports</span>
                </button>
              </div>
            </div>

            <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
            <div className="d-flex tableContainer  flex-column" ref={contentRef} >


            
              {!loading && (
                <table
                  className={`customTable ${darkmode ? 'darkTable' : ''}`}
                >
                  <thead>
                    <tr>
                      <th className="p-2  text-left"  onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('softwareName');
                          }}>Software Name    {sortColumn === 'softwareName' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}</th>
                      <th className="p-2 col-2 text-left"  onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('version');
                          }}> Version    {sortColumn === 'version' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}</th>
                      <th className="p-2 text-left"  onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('cveId');
                          }}> CVE ID    {sortColumn === 'cveId' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}</th>
                      <th className="p-2 col-3 text-left"  onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('description');
                          }}>Description    {sortColumn === 'description' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}</th>
                      <th className="p-2  text-left"  onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('severity');
                          }}>Severity    {sortColumn === 'severity' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}</th>
                      <th className="p-2 text-left"  onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('createdAt');
                          }}>Date Detected    {sortColumn === 'createdAt' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}</th>
                      <th className="p-2 text-left"  onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('id');
                          }}> Endpoint ID    {sortColumn === 'id' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}</th>
                      
                      
                    </tr>
                  </thead>
                  <tbody className="mb-3">
                    {vulnerabilityPaginatedData?.map((data: any) => (
                      <tr key={data.id}>

                        <td className="p-2 text-left">{data.softwareName}</td>
                        <td className="p-2 text-left tooltipDiv">{data.version?.slice(0, 25)}{data.version?.length > 25 ? '...' : ''}
                        <p className="tooltipText">{data.version}</p>
                        </td>
                        <td className="p-2 text-left">{data.cveId}</td>
                        <td className="p-2 text-left tooltipDiv">{data.description.slice(0, 45)}{data.description.length > 40 ? '...' : ''}
                        <p className="tooltipTextBottom">{data.description}</p>
                        </td>

                        <td className="p-2 text-left">{data.severity}</td>
                        <td className="p-2 text-left">{formatDatetimeToDate(data.createdAt)}</td>
                        <td className="p-2 text-left tooltipDiv">{data.id.slice(0, 25)}{data.id.length > 25 ? '...' : ''}
                        <p className="tooltipText">{data.id}</p>
                        </td>
                        
                       
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {loading && (
                <p className={` ${darkmode ? 'noRecordsDark' : 'noRecords'}`}>
                  Loading...
                </p>
              )}
              {!loading && listingData.length === 0 && (
                <p
                  className={`${darkmode ? 'noRecordsDark' : 'noRecords'} mt-0`}
                >
                  No Acknowledged Vulnerability Found
                </p>
              )}
            </div>
            </div>
          </Col>
        </div>
      </div>
    </Container>
  );
}

export default AckVulnerabilityReports;
