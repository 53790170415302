
// import env from "@app/env";

const baseURL = (window as any).__RUNTIME_CONFIG__.REACT_APP_SERVER_BASE_URL;

export const SWAGGER_BASE_URL = `${baseURL}`;
export const SWAGGER_LOGIN = `${SWAGGER_BASE_URL}/ui/user/login`;
export const SWAGGER_LOGOUT = `${SWAGGER_BASE_URL}/ui/user/logout`;
export const USER_HASH = `${SWAGGER_BASE_URL}/ui/user/hash`;
export const SWAGGER_USER_PROFILE = `${SWAGGER_BASE_URL}/ui/user/profile`;
export const SWAGGER_GET_ALL_USERS = `${SWAGGER_BASE_URL}/ui/user/profiles`;
export const GET_USERS_COUNT = `${SWAGGER_BASE_URL}/ui/user/count`;
export const GET_USER_ROLES = `${SWAGGER_BASE_URL}/ui/config/roles`;
export const SWAGGER_GET_AGENTS = `${SWAGGER_BASE_URL}/ui/agent/agents`;
export const SWAGGER_GET_AGENTS_ENDPOINTS = `${SWAGGER_BASE_URL}/ui/agent/endpointIds`;
export const SWAGGER_GET_AGENTS_OS_LISTS = `${SWAGGER_BASE_URL}/ui/agent/list/os`;
export const SWAGGER_DOWNLOAD_AGENTS_LICENSE = `${SWAGGER_BASE_URL}/ui/agent/license`;
export const SWAGGER_GET_AGENT_DETAILS = `${SWAGGER_BASE_URL}/ui/agent/info`;
export const AGENT_ALIAS = `${SWAGGER_BASE_URL}/ui/agent/alias`;
export const AGENT_FUNCTION_VALUES = `${SWAGGER_BASE_URL}/ui/config/function`;
export const BROWSER_HISTORY = `${SWAGGER_BASE_URL}/ui/browserhistory`;
export const PATCH_AGENT_FUNCTION = `${SWAGGER_BASE_URL}/ui/agent/function`;
export const DEACTIVATE_AGENT = `${SWAGGER_BASE_URL}/ui/agent/deactivate`;
export const FORGOT_PASSWORD = `${SWAGGER_BASE_URL}/ui/user/forgot`;
export const GET_FORGOT_USERNAME = `${SWAGGER_BASE_URL}/ui/user/getForgotUsername`;
export const GET_RESET_PASSWORD = `${SWAGGER_BASE_URL}/ui/user/resetPassword`;
export const SWAGGER_CHANGE_PASSWORD = `${SWAGGER_BASE_URL}/ui/user/profile/password`;
export const RBAC_MODULE = `${SWAGGER_BASE_URL}/ui/user/aduserprofiles`;
export const GET_AGENTS_LOCATION = `${SWAGGER_BASE_URL}/ui/agent/agents`;
export const GET_SETTINGS = `${SWAGGER_BASE_URL}/ui/config/settings`;
export const GET_SETTINGS_DOWNLOAD = `${SWAGGER_BASE_URL}/debug/license`;
export const GET_ALERT_COUNT = `${SWAGGER_BASE_URL}/ui/alert/count`;
export const GET_AGENT_SOFTWARE = `${SWAGGER_BASE_URL}/ui/agent/info/software`;
export const GET_AGENT_APP_USAGE = `${SWAGGER_BASE_URL}/ui/agent/info/appusage`;
export const GET_AGENT_SERVICE = `${SWAGGER_BASE_URL}/ui/agent/list/services/`;
export const GET_AGENT_COUNT = `${SWAGGER_BASE_URL}/ui/agent/count`;
export const GET_AGENT_REPORTS = `${SWAGGER_BASE_URL}/ui/report/agent`;
export const GET_ALERTS_REPORTS = `${SWAGGER_BASE_URL}/ui/report/alert`;
export const GET_ALERTS = `${SWAGGER_BASE_URL}/ui/alert`;
export const GET_ALERTS_ROLES = `${SWAGGER_BASE_URL}/ui/config/alerts`;
export const GET_SEVERITY = `${SWAGGER_BASE_URL}/ui/config/vulnerability_severity`;
export const GET_ALERTS_COUNT = `${SWAGGER_BASE_URL}/ui/alert/count`;
export const GET_ALERTS_CONFIG = `${SWAGGER_BASE_URL}/ui/alert/alertConfig`;
export const GET_TRUSTED_SOFTWARE = `${SWAGGER_BASE_URL}/ui/softwareinventory/list`;
export const UPLOAD_TRUSTED_SOFTWARE = `${SWAGGER_BASE_URL}/ui/softwareinventory/upload`;
export const ADD_TRUSTED_SOFTWARE = `${SWAGGER_BASE_URL}/ui/softwareinventory`;
export const TRUSTED_SOFTWARE_VALIDATION = `${SWAGGER_BASE_URL}/ui/softwareinventory/addToValidated`;
export const SOFTWARE_INVENTORY_REPORTS = `${SWAGGER_BASE_URL}/ui/report/info/software`;
export const SOFTWARE_INVENTORY_REPORTS_DOWNLOAD = `${SWAGGER_BASE_URL}/ui/report/software`;
export const SERVICE_REPORTS_DOWNLOAD = `${SWAGGER_BASE_URL}/ui/report/service`;
export const SOFTWARE_VULNERABILITY_REPORTS_DOWNLOAD = `${SWAGGER_BASE_URL}/ui/report/vulnerability`;
export const PORTS_REPORTS = `${SWAGGER_BASE_URL}/ui/report/ports`;
export const USER_PROFILE = `${SWAGGER_BASE_URL}/ui/user/profile/image`;
export const GET_THREATS_RISKS_LIST = `${SWAGGER_BASE_URL}/ui/risk/list`;
export const GET_VULNERABILITY = `${SWAGGER_BASE_URL}/ui/vulnerability`;
export const GET_VULNERABILITYCveId = `${SWAGGER_BASE_URL}/ui/nvd`;
export const GET_VULNERABILITY_COUNT = `${SWAGGER_BASE_URL}/ui/vulnerability/count`;
export const CONFIGURE_DEBUG_X = `${SWAGGER_BASE_URL}/debug/x`;
export const DEBUG_SERVER = `${SWAGGER_BASE_URL}/debug/server`;
export const ENTITLEMENT = `${SWAGGER_BASE_URL}/ui/user/subscription`;
export const CONTACT_US = `${SWAGGER_BASE_URL}/ui/customersupport`;

