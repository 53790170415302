import Container from '@app/components/CustomDash/Container';
import {ContentHeader} from '@app/components';
import {swaggerGetAgents, getAgentEndpointIds} from '@app/services/agents';
import React, {useEffect, useState} from 'react';
import {Button, Col, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  setIsAdmin,
  setAllUserProfiles,
  setSelectedUser,
  setUserEditMode
} from '@app/store/reducers/auth';
import {
  setAgentsPageNumber,
  setAllAgentProfiles
} from '@app/store/reducers/agents';
import {formatDate, handleInputChange} from '@app/utils/helpers';
import {useNavigate} from 'react-router-dom';
// import './Agents.css';
import ReactPaginate from 'react-paginate';

import InfoIcon from '@app/components/common/InfoIcon';
import Pagination from '@app/components/common/Pagination';
import {
  getSoftwareInventoryReports,
  getSoftwareInventoryReportsDownload
} from '@app/services/trustedSoftware';
import {toast} from 'react-toastify';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';
import NoOfRecords from '@app/components/common/NoOfRecords';
import SearchDropDown from '../agents/SearchDropDown';

const SoftwareInventoryReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  const [agentId, setAgentId] = useState('all');
  const [listingData, setListingData] = useState([]);
  const [filterlistingData, setFilterListingData] = useState([]);
  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);

  const token = useSelector((state: any) => state.auth.token);
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);
  const [clearInput, setClearInput] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  // Perform array slicing based on the current page
  const startIndex = (pageNumber - 1) * numberOfRecords;
  const endIndex = pageNumber * numberOfRecords;
  const paginatedListingData = listingData?.slice(startIndex, endIndex);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  // TODO FIX BUGS
  const sortTableData = (column: any) => {
    const sortedData = [...listingData];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      if (sortOrder === 'asc' || sortOrder === null) {
        // sort aesc if the current order is asc or null
        sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
        setSortColumn(column);
        setSortOrder('asc');
      } else {
        sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
        setSortColumn(column);
        setSortOrder('desc');
      }
    }

    setListingData(sortedData);
  };

  const fetchSoftwareInventoryReports = () => {
    setListingData([])
    getSoftwareInventoryReportsDownload(token, agentId,false)
      .then((res) => {
        const {data} = res;
        setListingData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const downloadCSV = () => {
    getSoftwareInventoryReportsDownload(token, agentId,true)
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Software Inventory Report.csv'; // Replace with the desired filename and extension
        a.click();
        // Release the temporary URL
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error('Failed to download Software Inventory Reports');
      });
  };

  const getAllAgents = () => {
    const cachedData = localStorage.getItem('cachedEndpoints');

    if (cachedData) {
      setFilterListingData(JSON.parse(cachedData));
    } else {
      getAgentEndpointIds(token)
        .then((response) => {
          const {data} = response;
          setFilterListingData(data);
          localStorage.setItem('cachedEndpoints', JSON.stringify(data));
        })
        .catch((err) => {
          setFilterListingData([]);
        });
    }
  };

  useEffect(() => {
    if (paginatedListingData?.length === 0) {
      setPageNumber(1);
    }
  }, [paginatedListingData]);

  useEffect(() => {
    getAllAgents();
    fetchSoftwareInventoryReports();
  }, []);



  const handleClear = () => {
    
    getSoftwareInventoryReportsDownload(token, 'all',false)
    .then((res) => {
      const {data} = res;
      setListingData(data);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
    });
  };

  const handleSelect = (id: string) => {
    setAgentId(id);
  };

  const handleClearAgents = () => {
    setClearInput(true);
  };

  const handleClearComplete = () => {
    setClearInput(false);
  };

  const renderSoftwareReports = paginatedListingData?.map((i: any) => (
    <tr key={i.id} className="" style={{cursor: 'pointer'}}>
      <td className=" text-left p-2">{i.name}</td>
      <td className=" text-left p-2">{i.version}</td>
      <td className=" text-left p-2">{i.publisher}</td>
      <td className=" text-left p-2">{formatDate(i.installedAt)}</td>
    </tr>
  ));

  // const renderSoftwareAgentData = filterlistingData?.map((i: any) => (
  //   <option value={i}>{i}</option>
  // ));
  return (
    <Container>
      <div>
        {/* <ContentHeader title="Agents" /> */}

        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <Col xs={12}>
              <div>
                <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
                  <h3 className="subHeaders"> </h3>
                  <div className="d-flex flex-wrap align-items-center justify-content-end">
                    <NoOfRecords
                      value={numberOfRecords}
                      setValue={(value) => setNumberOfRecords(value)}
                      totalRecords={listingData?.length}
                    />

                    {listingData?.length !== 0 && (
                      <div className="paginationContainer">
                        <Pagination
                          className="mr-1"
                          currentPage={pageNumber}
                          recordsPerPage={numberOfRecords}
                          totalRecords={listingData?.length}
                          onPageChange={(page: number) => setPageNumber(page)}
                        />
                      </div>
                    )}

                    <button
                      className="reportsBackButton mr-1"
                      onClick={() => {
                        {
                          navigate('/reports');
                        }
                      }}
                      id="si-reports-back-btn"
                    >
                        <i className="fa-solid fa-left-long"></i>
                      <span>Back</span>
                    </button>

                    <button
                      type="button"
                      className="m-1 filterButton mainFilterButton"
                      onClick={downloadCSV}
                      id="si-reports-download-btn"
                    >
                      <i className="fa-solid fa-download"></i>
                      <span className="ml-2">Reports</span>
                    </button>

                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="">filter to get the software information.</Tooltip>}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          setFilter(!filter);
                        }}
                        className="m-1 filterButton mainFilterButton"
                        id="si-reports-filter-btn"
                      >
                        <i className="fa-solid fa-filter" />
                        <span className="ml-2">Filter</span>
                      </button>
                    </OverlayTrigger>
                  </div>
                </div>

                {filter && (
                  <section
                    className={` FilterOption ${
                      darkmode && 'darkSecondaryWrapper'
                    } col-lg-10 col-md-12 mx-auto mb-0 d-flex  flex-column`}
                  >
                    <Form className="d-flex flex-wrap  ">
                      <Form.Group
                        controlId="role"
                        className="col-lg-4 col-md-5 col-12"
                      >
                        <Form.Label>EndpointID/ Hostname/Alias</Form.Label>
                        {/* <Form.Control
                          as="select"
                          value={agentId}
                          onChange={(e) => {
                            setAgentId(e.target.value);
                          }}
                          id='si-endpoint-id-option'
                        >
                          <option value="">--Select--</option>
                          {renderSoftwareAgentData}
                        </Form.Control> */}
                        <SearchDropDown
                          data={filterlistingData}
                          isReport={true}
                          onSelect={handleSelect}
                          clearInput={clearInput}
                          onClearComplete={handleClearComplete}
                          isManagedEndPointPage={false}
                        />
                      </Form.Group>
                      {/* <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter city"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Os Type</Form.Label>
                        <Form.Control
                          as="select"
                          value={osType}
                          onChange={(e) => {
                            setOsType(e.target.value);
                          }}
                        >
                          <option value="">--Select--</option>
                          <option value="Windows">Windows</option>
                          <option value="macOS">macOS</option>
                          <option value="Ubuntu">Ubuntu</option>
                          <option value="Kali GNU/Linux">Kali GNU/Linux</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={status}
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        >
                          <option value="">--Select--</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </Form.Control>
                      </Form.Group> */}
                    </Form>

                    {/* <div className="d-flex justify-content-start mt-4 pl-1">
                      <button
                        type="button"
                        className="m-1 pointer"
                        onClick={() => {
                          fetchFilteredAgents(token);
                        }}
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        className="m-1 pointer"
                        onClick={() => {
                          setAgentId('');
                          setCity('');
                          setStatus('');
                          setTimeout(() => {
                            getAllAgents();
                          }, 100);
                        }}
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="Close m-1 pointer"
                        onClick={() => {
                          setFilter(false);
                        }}
                      >
                        Close
                      </button>
                    </div> */}
                    <div className="d-flex flex-wrap justify-content-center mt-4 pl-1">
                      <button
                        type="button"
                        className=" pointer my-2 my-sm-0 alertFilterButton"
                        onClick={() => {
                          fetchSoftwareInventoryReports();
                        }}
                        id="si-apply-btn"
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        className="mx-2 my-2 my-sm-0 pointer alertFilterButton"
                        onClick={() => {
                          handleClear();
                          handleClearAgents();
                        }}
                        id="si-clear-btn"
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="Close my-2 my-sm-0  pointer alertFilterButton"
                        onClick={() => {
                          setFilter(false);
                        }}
                        id="si-close-btn"
                      >
                        Close
                      </button>
                    </div>
                  </section>
                )}
                {listingData.length !== 0 && (
                  <div className="mobilePaginationContainer">
                    <Pagination
                      className="mr-1"
                      currentPage={pageNumber}
                      recordsPerPage={numberOfRecords}
                      totalRecords={listingData?.length}
                      onPageChange={(page: number) => setPageNumber(page)}
                    />
                  </div>
                )}

                <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
                  <div className="d-flex tableContainer overflow-auto flex-column">
                    {/* <div className="rounded"> */}
                    <table
                      className={`customTable ${darkmode ? 'darkTable' : ''}`}
                    >
                      <thead className="">
                        <tr>
                          <th
                            // className="p-2 col-2 text-left pl-4"
                            className="p-2 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('name');
                            }}
                          >
                            Software
                            {sortColumn === 'name' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 text-left"
                            onClick={() => {
                              sortTableData('version');
                            }}
                          >
                            Version 
                            {sortColumn === 'version' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            // className="p-2 col-2 text-left"
                            className="p-2 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('publisher');
                            }}
                          >
                            Publisher
                            {sortColumn === 'publisher' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            // className="p-2 col-2 text-left"
                            className="p-2 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('installedAt');
                            }}
                          >
                            Date Installed
                            {sortColumn === 'installedAt' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                        
                          
                        </tr>
                      </thead>
                      {!loading && paginatedListingData.length > 0 && (
                      <tbody className="">{renderSoftwareReports}</tbody>
                     )} 
                    </table>
                    {/* </div> */}
                    {loading && (
                      <p
                        className={`mt-0 ${
                          darkmode ? 'noRecordsDark' : 'noRecords'
                        }`}
                      >
                        Loading...
                      </p>
                    )}
                    {!loading && paginatedListingData.length === 0 && (
                      <p
                        className={` mt-0 ${
                          darkmode ? 'noRecordsDark' : 'noRecords'
                        }`}
                      >
                        No Software Inventory Found..
                      </p>
                    )}
                    {!loading && paginatedListingData === null && (
                      <p
                        className={`mt-0 ${
                          darkmode ? 'noRecordsDark' : 'noRecords'
                        }`}
                      >
                        Network Error...
                      </p>
                    )}
                    {/* <ReactPaginate
                      breakLabel="..."
                      nextLabel="Next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="< Previous"
                      renderOnZeroPageCount={null}
                      containerClassName={'pagination'}
                      pageLinkClassName={'page-num'}
                      previousLinkClassName={'page-num'}
                      nextLinkClassName={'page-num'}
                      activeLinkClassName={'active'}
                    /> */}
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>

        {/* <UserFormModal
      show={showUserForm}
      onHide={() => setShowUserForm(false)}
    /> */}
      </div>
    </Container>
  );
};

export default SoftwareInventoryReports;
