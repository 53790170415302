import axios from 'axios';

import {
  ENTITLEMENT,
   USER_PROFILE
  } from './constants/swaggerConfig';


  export const getProfileImage = (token: string) => {
    return axios.get(`${USER_PROFILE}/${token}`);
  };
  export const getEntitlement = (value: boolean) => {
    return axios.get(`${ENTITLEMENT}/${value}`);
  };

  export const uploadProfileImage = (token :string,formData:any) => {
    return axios.post(`${USER_PROFILE}/${token}`,formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }}
    )
}