import axios from 'axios';
import {
  GET_ALERTS,
  GET_ALERTS_COUNT,
  GET_ALERTS_REPORTS,
  GET_ALERTS_ROLES,
  SOFTWARE_VULNERABILITY_REPORTS_DOWNLOAD
} from './constants/swaggerConfig';

export const swaggerGetAlertList = (
  token: string,
  agentId: string,
  type: string,
  acknowledged: boolean | string,
  dateStart: string,
  dateEnd: string,
  count?: number
) => {
  return axios.get(
    `${GET_ALERTS}/list/${token}?${agentId ? `agentId=${agentId}` : ``}${
      type ? `&type=${type}` : ``
    }${acknowledged !== 'undefined' ? '&acknowledged=' + acknowledged:''}${
      dateStart ? `&createStart=${dateStart}` : ``
    }${dateEnd ? `&createEnd=${dateEnd}` : ``}${count ?`&count=${count}`:``}`
  );
};
export const swaggerGetAlertAck = (
  token: string,
  id: string | undefined,
  comment: string
) => {
  return axios.post(
    `${GET_ALERTS}/${id}/${token}/ack?${comment ? `comment=${comment}` : ``}`
  );
};
export const swaggerGetAlertDetails = (token: string, id: any) => {
  return axios.get(`${GET_ALERTS}/${id}/${token}`);
};
export const getAlertTypes = (token: string) => {
  return axios.get(`${GET_ALERTS_ROLES}/${token}`);
};

export const getAlertsCount = (
  token: string,
  agentId?: string,
  type?: string,
  acknowledged?: boolean | string,
  dateStart?: string,
  dateEnd?: string
) => {
  return axios.get(
    `${GET_ALERTS_COUNT}/${token}?${agentId ? 'agentId=' + agentId:''}${
      type ? '&type=' + type:''
    }${acknowledged !== 'undefeined' ? '&acknowledged=' + acknowledged:''}${
      dateStart ? '&createStart=' + dateStart:''
    }${dateEnd ? '&createEnd=' + dateEnd:''}`
  );
};

export const getUnacknowledgedAlerts = (
  token: string,
  agentId: string = '',
  alertType: string = ''
) => {
  return axios.get(
    `${GET_ALERTS_REPORTS}/${token}?agentId=${agentId}&type=${alertType}`
  );
};
export const getAcknowledgedAlerts = (
  token: string,
  agentId: string = '',
  alertType: string = ''
) => {
  return axios.get(
    `${GET_ALERTS_REPORTS}/acknowledged/${token}?agentId=${agentId}&type=${alertType}`
  );
};

export const getUnacknowledgedAlertsDownlaod = (
  token: string,
  agentId: string = '',
  alertType: string = ''
) => {
  return axios.get(`${GET_ALERTS_REPORTS}/${token}?count=100&download=true`);
};
export const getVulnerabilityDownlaod = (token: string, download:boolean ,acknowledged?: boolean | string) => {
  return axios.get(`${SOFTWARE_VULNERABILITY_REPORTS_DOWNLOAD}/${token}${acknowledged !== 'undefeined' ? '?acknowledged=' + acknowledged:''}&download=${download}`);
};

export const getAcknowledgedAlertsDownlaod = (
  token: string,
  agentId: string = '',
  alertType: string = ''
) => {
  return axios.get(
    `${GET_ALERTS_REPORTS}/acknowledged/${token}?count=100&download=true`
  );
};
