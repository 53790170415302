import {getEntitlement, getProfileImage} from '@app/services/account';
import {
  getAlertCount,
  logoutBySwagger,
  swaggerGetProfile
} from '@app/services/swaggerAuth';
import {setDarkMode, setImage} from '@app/store/reducers/Account';
import {loadUser, logoutUser, setIsAdmin} from '@app/store/reducers/auth';
import {sleep} from '@app/utils/helpers';
import {response} from 'express';
import React, {ReactNode, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {PfImage} from '@profabric/react-components';
import styled from 'styled-components';
import {NavDropdown} from 'react-bootstrap';
import UserManuel from '@app/pages/dashbords/UserManuel';
//import ContactUs from '@app/pages/ContactUs';
import {swaggerGetAlertList} from '@app/services/alerts';
import {getSettings} from '@app/services/settings';
import ContactUs from '@app/pages/ContactUs';
import {Settings} from '@app/pages/dashbords/Dashboard';
import {VERSION_NUMBER} from '@app/utils/version';
import {
  setInsights,
  setProductivity,
  setRBAC,
  setThreats,
  setTrustedSoftware
} from '@app/store/reducers/Account';

interface Props {
  children: ReactNode;
}

function Container({children}: Props) {
  const [t] = useTranslation();

  let location = useLocation();

  const [first, setfirst] = useState<boolean>(true);
  const [path, setPath] = useState<string>('');
  const [profileDrop, setProfileDrop] = useState<boolean>(false);
  const [menuToggleTwo, setMenuToggleTwo] = useState<boolean>(false);
  const [menuToggleThree, setMenuToggleThree] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [alertCount, setAlertCount] = useState(0);
  const [showUserManuel, setShowUserMAnuel] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const userName = localStorage.getItem('userName');

  const StyledUserImage = styled(PfImage)`
    // --pf-border: 3px solid #adb5bd;
    --pf-padding: 3px;
  `;

  useEffect(() => {
    setPath(location.pathname.replace('/', ''));
  }, []);

  useEffect(() => {

   
      if (path.split('/')[1] === 'alert-config') {
        setShowSettings(true);
      }
    
  }, [path.split('/')[1] === 'alert-config']);

  console.log(path);

  const admin = localStorage.getItem('role');

  const token = useSelector((state: any) => state.auth.token);
  const currentUser = useSelector((state: any) => state.auth.currentUser);
  const image = useSelector((state: any) => state.account.image);
  const darkmode = useSelector((state: any) => state.account.darkmode);
  const {insight, productivity, rbac, threats, trustedSoftware} = useSelector(
    (state: any) => state.account
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchProfile = async () => {
    const cachedUser = localStorage.getItem('cachedUser');
    if (cachedUser) {
      dispatch(loadUser(JSON.parse(cachedUser)));
    } else {
      try {
        const response = await swaggerGetProfile(token);
        const userData = response.data;
        dispatch(loadUser(userData));
        localStorage.setItem('cachedUser', JSON.stringify(userData));
        await sleep(1000);
      } catch (error) {
        dispatch(logoutUser());
        await sleep(1000);
      }
    }
  };

  const fetchProfileImg = async () => {
    const cachedImage = localStorage.getItem('cachedImage');
    if (cachedImage) {
      dispatch(setImage(JSON.parse(cachedImage)));
      setLoader(false);
    } else {
      getProfileImage(token)
        .then((res) => {
          const imageData = res.data;
          dispatch(setImage(imageData));
          localStorage.setItem('cachedImage', JSON.stringify(imageData));
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchInitialAlertsData = () => {
    const tempAgentId = '';
    const tempType = '';
    const tempAcknowledge = false;
    const tempDateStart = '';
    const tempDateEnd = '';
    swaggerGetAlertList(
      token,
      tempAgentId,
      tempType,
      tempAcknowledge,
      tempDateStart,
      tempDateEnd
    ).then((res) => {
      // Cache the data in localStorage
      localStorage.setItem('cachedAlertData', JSON.stringify(res.data));
    });
  };

  const fetchCatchedAlertsData = () => {
    const cachedData = localStorage.getItem('cachedAlertData');
    if (!cachedData) {
      fetchInitialAlertsData(); // Fetch data if not cached
    }
  };

  const fetchSettings = (token: string) => {
    getSettings(token).then((response) => {
      const {data} = response;
      const onlineSettings = data.find(
        (item: Settings) => item.key === 'Online'
      );

      // const agentsSettings = data[2];
      localStorage.setItem('cachedSettingsData', data);
      localStorage.setItem('onlineThresholdValue', onlineSettings?.value);
      // localStorage.setItem('agentsSettingsValue', agentsSettings?.value);
    });
  };

  const fetchCatchedSettingsData = async () => {
    const cachedData = localStorage.getItem('cachedSettingsData');
    if (!cachedData) {
      fetchSettings(token); // Fetch data if not cached
    }
  };

  const checkIfAdmin = (user: any) => {
    if (user.roles === 'ADMIN') {
      dispatch(setIsAdmin(true));
    } else {
      dispatch(setIsAdmin(false));
    }
  };

  console.log(showSettings)

  useEffect(() => {
    const initialApiCalls = async () => {
      await fetchProfile();
      await fetchProfileImg();
      await fetchCatchedSettingsData();
      // fetchCatchedAlertsData();
    };

    initialApiCalls();
  }, []);


  useEffect(() => {
    getEntitlement(token)
      .then((res) => {
        let data = res.data;
        dispatch(setInsights(data.featureInsight));
        dispatch(setProductivity(data.featureProductivity));
        dispatch(setRBAC(data.featureRBAC));
        dispatch(setThreats(data.featureThreats));
        dispatch(setTrustedSoftware(data.featureTrustedSoftware));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (currentUser) {
      checkIfAdmin(currentUser);
    }
  }, [currentUser]);

  return (
    <div id="wrapper" className={`${darkmode ? 'darkWrapper' : ''}`}>
      <ul
        className={`navbar-nav  navBarCustom customHei sidebar ${
          darkmode ? 'darkSecondaryWrapper' : ''
        }  sidebar-dark accordion ${first && 'toggled'}
         `}
        id="accordionSidebar"
      >
        {/* <!-- Sidebar - Brand --> */}
        <Link className="sidebar-brand ml-1" to="/">
          <div className="">
            <img
              className="bandlogoImg"
              id="home-logo"
              src="/img/logoDesktop.png"
              alt=""
            />
          </div>
        </Link>

        {/* <!-- Nav Item - Dashboard --> */}
        <li className="nav-item mt-5">
          {' '}
          <NavLink
            to={'/'}
            className={`nav-link ${path == '' && 'activatedContainer'}`}
            id="dashboard-header"
          >
            <div>
              {path == '' ? (
                !darkmode ? (
                  <img src="/img/dashboardIcon.png" alt="" />
                ) : (
                  <img src="/img/dashboardActive.png" alt="" />
                )
              ) : darkmode ? (
                <img src="/img/dashboardIcon.png" alt="" />
              ) : (
                <img src="/img/dashboardActive.png" alt="" />
              )}
            </div>

            <span className={`${path == '' && 'activated'}`}>Dashboard</span>
          </NavLink>
        </li>

        <li className="nav-item mt-2">
          <NavLink
            className={`nav-link ${
              path.split('/')[0] == 'agents' && 'activatedContainer'
            }`}
            to={'/agents'}
            id="managed-endpoints-header"
          >
            <div>
              {path.split('/')[0] == 'agents' ? (
                !darkmode ? (
                  <img src="/img/agents icon.png" alt="" />
                ) : (
                  <img src="/img/agentsActive.png" alt="" />
                )
              ) : darkmode ? (
                <img src="/img/agents icon.png" alt="" />
              ) : (
                <img src="/img/agentsActive.png" alt="" />
              )}
            </div>

            <span
              className={`${path.split('/')[0] == 'agents' && 'activated'}`}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              Managed Endpoints
            </span>
          </NavLink>
        </li>

        {!threats ? (
          ''
        ) : (
          <li className="nav-item mt-2">
            <NavLink
              className={`nav-link ${
                path.split('/')[0] == 'vulnerability' && 'activatedContainer'
              }`}
              to="/vulnerability"
              id="vulnerabilities-header"
            >
              <div>
                {path.split('/')[0] === 'vulnerability' ? (
                  !darkmode ? (
                    <img src="/img/threats_icon.png" alt="" />
                  ) : (
                    <img src="/img/threatsActive.png" alt="" />
                  )
                ) : darkmode ? (
                  <img src="/img/threats_icon.png" alt="" />
                ) : (
                  <img src="/img/threatsActive.png" alt="" />
                )}
              </div>
              <span
                className={`${
                  path.split('/')[0] === 'vulnerability' && 'activated'
                }`}
              >
                Vulnerabilities
              </span>
            </NavLink>
          </li>
        )}

        <li className="nav-item mt-2">
          <NavLink
            className={`nav-link ${path == 'alerts' && 'activatedContainer'}`}
            to={'/alerts'}
            id="alerts-header"
          >
            {path == 'alerts' ? (
              !darkmode ? (
                <img src="/img/alerts icon.png" alt="" />
              ) : (
                <img src="/img/alertsActive.png" alt="" />
              )
            ) : darkmode ? (
              <img src="/img/alerts icon.png" alt="" />
            ) : (
              <img src="/img/alertsActive.png" alt="" />
            )}

            <span className={`${path == 'alerts' && 'activated'}`}>Alerts</span>
          </NavLink>
        </li>

        <li className="nav-item mt-2">
          <NavLink
            className={`nav-link ${
              path.split('/')[0] == 'reports' && 'activatedContainer'
            }`}
            to={'/reports'}
            id="reports-header"
          >
            <div>
              {path.split('/')[0] == 'reports' ? (
                !darkmode ? (
                  <img src="/img/report 1.png" alt="" />
                ) : (
                  <img src="/img/reportsActive.png" alt="" />
                )
              ) : darkmode ? (
                <img src="/img/report 1.png" alt="" />
              ) : (
                <img src="/img/reportsActive.png" alt="" />
              )}
            </div>

            <span
              className={`${path.split('/')[0] == 'reports' && 'activated'}`}
            >
              Reports
            </span>
          </NavLink>
        </li>

        {/* {!rbac ? (
          ''
        ) : (
          <li className="nav-item mt-2">
            <NavLink
              className={`nav-link ${
                path == 'role-based-access-control' && 'activatedContainer'
              }`}
              to="/role-based-access-control"
              id="rbac-header"
            >
              {path === 'role-based-access-control' ? (
                !darkmode ? (
                  <img src="/img/RBAC.png" alt="" />
                ) : (
                  <img src="/img/RBACActive.png" alt="" />
                )
              ) : darkmode ? (
                <img src="/img/RBAC.png" alt="" />
              ) : (
                <img src="/img/RBACActive.png" alt="" />
              )}

              <span
                className={`${
                  path === 'role-based-access-control' && 'activated'
                }`}
              >
                RBAC
              </span>
            </NavLink>
          </li>
        )} */}

        {!trustedSoftware ? (
          ''
        ) : (
          <li className="nav-item mt-2">
            <NavLink
              className={`nav-link ${
                path == 'trusted-software' && 'activatedContainer'
              }`}
              to={'/trusted-software'}
              id="validated-software-header"
            >
              {path == 'trusted-software' ? (
                !darkmode ? (
                  <img src="/img/trusted software 1.png" alt="" />
                ) : (
                  <img src="/img/trustedSoftwareActive.png" alt="" />
                )
              ) : darkmode ? (
                <img src="/img/trusted software 1.png" alt="" />
              ) : (
                <img src="/img/trustedSoftwareActive.png" alt="" />
              )}
              <span className={`${path == 'trusted-software' && 'activated'}`}>
                Validated Software
              </span>
            </NavLink>
          </li>
        )}

        {admin === 'ADMIN' && (
          <li className="nav-item mt-2">
            <NavLink
              className={`nav-link ${path == 'users' && 'activatedContainer'}`}
              to={'/users'}
              id="gitm-user-header"
            >
              {path == 'users' ? (
                !darkmode ? (
                  <img src="/img/users icon.png" alt="" />
                ) : (
                  <img src="/img/userActive.png" alt="" />
                )
              ) : darkmode ? (
                <img src="/img/users icon.png" alt="" />
              ) : (
                <img src="/img/userActive.png" alt="" />
              )}

              <span className={`${path === 'users' && 'activated'}`}>
                GITM Users
              </span>
            </NavLink>
          </li>
        )}

        {admin === 'ADMIN' && (
          <li className="nav-item mt-2">
            <NavLink
              className={`nav-link ${
                path.split('/')[0] == 'settings' && 'activatedContainer'
              }`}
              to={'/settings'}
              id="settings-header"
            >
              {path.split('/')[0] == 'settings' ? (
                !darkmode ? (
                  <img src="/img/settings icon.png" alt="" />
                ) : (
                  <img src="/img/settingsActive.png" alt="" />
                )
              ) : darkmode ? (
                <img src="/img/settings icon.png" alt="" />
              ) : (
                <img src="/img/settingsActive.png" alt="" />
              )}

              <span
                className={`${path.split('/')[0] == 'settings' && 'activated'}`}
              >
                Settings
              </span>

              <span
                className="iconContainer"
                onClick={() => {
                  setShowSettings(!showSettings);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={`${!showSettings && 'iconRotate90'}`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </NavLink>
            {showSettings && (
              <ul
                className={`menuSettings ${
                  showSettings ? 'slide-down' : 'slide-up'
                }`}
              >
                <li className="nav-item px-0 w-100">
                  <NavLink
                    className={
                      path.split('/')[1] === 'alert-config'
                        ? 'subActiveLink'
                        : 'subActiveLinkOff'
                    }
                    to="/settings/alert-config"
                  >
                    Alert Config
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        )}

        {/* <li className="nav-item">
          <a
            className={`nav-link ${!menuToggle && 'collapsed'}`}
            href="#"
            onClick={() => {
              setMenuToggle(!menuToggle);
              setMenuToggleTwo(false);
              setMenuToggleThree(false);
            }}
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded={menuToggle}
            aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-cog"></i>
            <span>Components</span>
          </a>
          <div
            id="collapseTwo"
            className={`collapse ${menuToggle && 'show'}`}
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Components:</h6>
              <a className="collapse-item" href="">
                Option one
              </a>
              <a className="collapse-item" href="">
                Option two
              </a>
            </div>
          </div>
        </li> */}

        {/* <!-- Nav Item - Utilities Collapse Menu --> */}

        {/* <!-- Divider --> */}
        {/* <hr className="sidebar-divider d-none d-md-block" /> */}

        {/* <!-- Sidebar Toggler (Sidebar) --> */}
        {/* <div className="text-center d-none d-md-inline">
          <button
            onClick={() => {
              setfirst(!first);
            }}
            className="rounded-circle border-0"
            id="sidebarToggle"
          ></button>
        </div> */}

        <div className="ver-item pl-5">
          <p>Version {VERSION_NUMBER}</p>
        </div>
      </ul>

      <div id="content-wrapper" className="d-flex flex-column">
        <div className="content h-100">
          <nav
            className={`navbar navbar-expand navbar-light topbar  static-top shadow ${
              darkmode ? 'darkSecondaryWrapper' : 'bg-white'
            }`}
          >
            {/* <!-- Sidebar Toggle (Topbar) --> */}
            <button
              id="sidebarToggleTop"
              onClick={() => {
                setfirst(!first);
              }}
              className="btn btn-link d-md-none rounded-circle mr-3"
            >
              <i className="fa fa-bars"></i>
            </button>

            <div>
              {path === '' && <h3 className="subHeaders">Dashboard</h3>}
              {path === 'agents' && (
                <h3 className="subHeaders">Managed Endpoints </h3>
              )}
              {path === 'vulnerability' && (
                <h3 className="subHeaders">Vulnerabilities</h3>
              )}
              {path === 'reports' && <h3 className="subHeaders">Reports</h3>}
              {path === 'users' && <h3 className="subHeaders"> GITM Users</h3>}
              {path === 'role-based-access-control' && (
                <h3 className="subHeaders">Role Based Access Control</h3>
              )}
              {path === 'trusted-software' && (
                <h3 className="subHeaders">Validated Software</h3>
              )}
              {path === 'alerts' && <h3 className="subHeaders">Alerts</h3>}
              {path === 'settings' && <h3 className="subHeaders">Settings</h3>}
              {path === 'profile' && <h3 className="subHeaders">Profile</h3>}
              {path.split('/')[0] === 'role-based-access-control-details' && (
                <h3 className="subHeaders">
                  Role Based Access Control Details
                </h3>
              )}
              {path?.split('/')[0] === 'agents' &&
                path?.split('/')[1] === 'agent-details' && (
                  <h3 className="subHeaders">Managed Endpoint Details</h3>
                )}
              {path?.split('/')[0] === 'alerts' &&
                path?.split('/')[1] === 'alert-details' && (
                  <h3 className="subHeaders">Alert Details</h3>
                )}
              {path?.split('/')[0] === 'vulnerability' &&
                path?.split('/')[1] === 'vulnerability-details' && (
                  <h3 className="subHeaders">Vulnerability Details</h3>
                )}
              {path?.split('/')[0] === 'reports' &&
                path?.split('/')[1] === 'agent-reports' && (
                  <h3 className="subHeaders">Managed Endpoint Report</h3>
                )}
              {path?.split('/')[0] === 'reports' &&
                path?.split('/')[1] === 'software-inventory-reports' && (
                  <h3 className="subHeaders">Software Inventory Report</h3>
                )}
              {path?.split('/')[0] === 'reports' &&
                path?.split('/')[1] === 'unacknowledged-alerts-reports' && (
                  <h3 className="subHeaders">Unacknowledged Alerts Report</h3>
                )}
              {path?.split('/')[0] === 'reports' &&
                path?.split('/')[1] === 'acknowledged-alerts-reports' && (
                  <h3 className="subHeaders">Acknowledged Alerts Report</h3>
                )}
              {path?.split('/')[0] === 'reports' &&
                path?.split('/')[1] === 'vulnerability-reports' && (
                  <h3 className="subHeaders">Vulnerability Report</h3>
                )}
              {path?.split('/')[0] === 'reports' &&
                path?.split('/')[1] ===
                  'acknowledged-vulnerability-reports' && (
                  <h3 className="subHeaders">
                    Acknowledged Vulnerability Report
                  </h3>
                )}
              {path?.split('/')[0] === 'reports' &&
                path?.split('/')[1] ===
                  'unacknowledged-vulnerability-reports' && (
                  <h3 className="subHeaders">
                    Unacknowledged Vulnerability Report
                  </h3>
                )}
              {path?.split('/')[0] === 'reports' &&
                path?.split('/')[1] === 'service-report' && (
                  <h3 className="subHeaders">Unique Service Report</h3>
                )}
              {path?.split('/')[0] === 'reports' &&
                path?.split('/')[1] === 'unique-vulnerability-report' && (
                  <h3 className="subHeaders">Unique Vulnerability Report</h3>
                )}
              {path?.split('/')[0] === 'reports' &&
                path?.split('/')[1] === 'open-ports-report' && (
                  <h3 className="subHeaders">Unique Open Ports Report</h3>
                )}
              {path?.split('/')[0] === 'settings' &&
                path?.split('/')[1] === 'alert-config' && (
                  <h3 className="subHeaders">Alert Configuration</h3>
                )}
            </div>

            {/* <!-- Topbar Search --> */}

            {/* <!-- Topbar Navbar --> */}
            <ul className="navbar-nav ml-auto align-items-center">
              {/* <!-- Nav Item - Messages --> */}
              <li className="helpContainer">
                <button
                  id="help-btn"
                  onClick={() => {
                    setShowUserMAnuel(true);
                  }}
                >
                  Help
                </button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                  />
                </svg>
              </li>
              {/* <li className="mx-4 contactUsContainer">
                <button
                  id="contact-us-btn"
                  onClick={() => {
                    setShowContact(true);
                  }}
                >
                  Contact Us
                </button>

                <svg
                  onClick={() => {
                    setShowContact(true);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0 6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
                  />
                </svg>
              </li> */}
              <li className="">
                {/* <button
                  id="logout-btn"
                  className="logOutButtonDeskTop"
                  onClick={handleLogout}
                >
                  Log Out
                </button>
                <i
                  className="fa-solid fa-arrow-right-from-bracket logoutIconCustom"
                  onClick={handleLogout}
                ></i> */}
              </li>

              {/* <!-- Nav Item - User Information --> */}
              <li className="nav-item dropdown no-arrow">
                <a
                  className="nav-link dropdown-toggle AdminWidget"
                  href="/profile"
                  id="userDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span
                    className="mr-2  d-lg-inline text-gray-600 small"
                    id="admin-text"
                  >
                    {userName}
                  </span>
                  {image ? (
                    <img
                      className="img-profile rounded-circle"
                      id="admin-img"
                      src={
                        image
                          ? `data:image/jpeg;base64,${image}`
                          : 'img/cyberzekLogo.png'
                      }
                    />
                  ) : (
                    <StyledUserImage
                      width={32}
                      height={32}
                      rounded
                      src="/img/default-profile-pic.png"
                      alt="User profile"
                    />
                  )}
                </a>
              </li>
            </ul>
          </nav>

          {children}
          <UserManuel
            show={showUserManuel}
            onHide={() => setShowUserMAnuel(false)}
          />
          {/* {showContact && <ContactUs setClose={setShowContact} />} */}
        </div>
      </div>
    </div>
  );
}

export default Container;
