/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import {Col, Form, InputGroup, Row} from 'react-bootstrap';
import axios from 'axios';
import * as Yup from 'yup';
import SHA256 from 'crypto-js/sha256';
import PasswordStrengthBar from 'react-password-strength-bar';

import {
  loadUser,
  loginUser,
  logoutUser,
  setClientIp,
  setCurrentUserPassword
} from '@store/reducers/auth';
import {ValidationResult, getStrengthColor, setWindowClass, sleep, validatePassword} from '@app/utils/helpers';
import {PfButton, PfCheckbox} from '@profabric/react-components';
import {/* hashLoginPassword ,*/ loginBySwagger, swaggerGetProfile} from '@app/services/swaggerAuth';
import * as AuthService from '../../services/auth';
import cyberzekLogo from '../../../public/img/logo.png';
import './login.css';
import {SWAGGER_BASE_URL} from '@app/services/constants/swaggerConfig';

const Login = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordDetails, setShowPasswordDetails] = useState(false);
  const [validation, setValidation] = useState<ValidationResult | null>(null);

  const ip = useSelector((state: any) => state.auth.clientIp);
  const baseURLRedux = useSelector((state: any) => state.auth.baseURLRedux);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const login = async (email: string, password: any) => {
    // *** Previous Implementation Reference - LoginByAuth ***
    /* try {
      setAuthLoading(true);
      const token = await AuthService.loginByAuth(email, password);
      toast.success('Login is succeed!');
      setAuthLoading(false);
      dispatch(loginUser(token));
      navigate('/');
    } catch (error: any) {
      setAuthLoading(false);
      toast.error(error.message || 'Failed');
    } */
    // console.log('process.env', process.env);

    // *** LoginBySwagger ***
    try {
      // console.log(email,password)
      setAuthLoading(true);
      const token = await loginBySwagger(email, password, ip, baseURLRedux);
      toast.success('Login Successful!');
      setAuthLoading(false);
      dispatch(loginUser(token));
      localStorage.setItem('userId', email);
      dispatch(setCurrentUserPassword(password));
      fetchProfile(token);
      navigate('/');
    } catch (error: any) {
      setAuthLoading(false);
      // console.log('error', error);
      if (error.response.status === 401)
        toast.error('Incorrect username or password');
    }
  };

  const fetchProfile = async (token: string) => {
    // Gatekeeper Code
    // try {
    //   const response = await Gatekeeper.getProfile();
    //   dispatch(loadUser(response));
    //   await sleep(1000);
    //   setIsAppLoaded(true);
    // } catch (error) {
    //   dispatch(logoutUser());
    //   await sleep(1000);
    //   setIsAppLoaded(true);
    // }

    try {
      const response = await swaggerGetProfile(token);
      dispatch(loadUser(response.data));
      await sleep(1000);
    } catch (error) {
      dispatch(logoutUser());
      await sleep(1000);
    }
  };

  // const hashLoginPassword = async (password: any) => {
  //   const encoder = new TextEncoder();
  //   const passwordBuffer = encoder.encode(password);
  //   const hashBuffer = await crypto.subtle.digest('SHA-256', passwordBuffer);
  //   const hashArray = Array.from(new Uint8Array(hashBuffer));

  //   return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  // };

  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Required')
        .matches(
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/,
          'Email format is incorrect'
        ),
      password: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required')
    }),
    onSubmit: (values) => {
       login(values.email, SHA256(values.password).toString() );
       
    }
  });

  setWindowClass('hold-transition login-page');

  // const [loading, setLoading] = useState(false);

  const handleAgentDownload = async () => {
 
    const toastId = toast.loading("Downloading...");
    try {
     
      const res = await fetch(`${SWAGGER_BASE_URL}/debug/download/msi`);
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cyberzek-agent.msi');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      toast.dismiss(toastId);
  toast.success('File downloaded successfully!');
    } catch (error) {
      toast.dismiss(toastId);
      navigate('/');
      toast.error('Failed to download file.');
    }
  };

  useEffect(() => {
    const fetchIPAdrress = async () => {
      const res = await axios.get('https://geolocation-db.com/json/');
      dispatch(setClientIp(res.data.IPv4));
    };

    fetchIPAdrress();
  }, []);

  useEffect(() => {
    if (showPassword) {
      setTimeout(() => {
        setShowPassword(false);
      }, 3000);
    }
  }, [showPassword]);

  return (
    <div className="login-root container d-flex align-items-center justify-content-center">
      <Row className="login-container">
        <Col
          className="left-side d-flex align-items-center justify-content-center"
          xs={12}
          md={6}
        >
          <div className="mt-4 w-100">
            <div className="logoImgContainer mt-5">
              <img
                className="logoImg"
                src="/img/logoDesktop.png"
                alt=""
              />
              <img
                className="logoImgMobile"
                src="/img/logoMobile.png"
                alt=""
              />
            </div>
            <div className='welcomeContainer'>
            <h1 className="text-center">Welcome!</h1>
            {/* <div style={{position: 'relative'}} className="login-download">
              <h4 className="download-title mb-3 mt-5">Download For:</h4>
              <div className="download-links d-flex justify-content-center">
                {/* <a href="#">Windows</a> */}
                {/* <a href="#" onClick={() => handleAgentDownload()}>
                  Windows
                </a> */}
                {/* <a href="#">Mac</a>
                <a href="#">Linux</a> */}
              {/* </div>
            </div> */} 
            </div>
           
          </div>
        </Col>
        <Col className="right-side">
          <div className="wrapper-container">
            <div className="mt-1">
              <h3>Login</h3>
            </div>

            <form className="form-container" onSubmit={handleSubmit}>
              <div className="mt-2">
                <Form.Label>Email</Form.Label>
                <div className="mb-3">
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="email"
                      name="email"
                      type="email"
                      placeholder="username@cyberzek.com"
                      onChange={handleChange}
                      value={values.email}
                      // isValid={touched.email && !errors.email}
                      isInvalid={touched.email && !!errors.email}
                    />
                    {touched.email && errors.email ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    ) : (
                      <InputGroup.Append>
                        <InputGroup.Text>
                          <i className="fas fa-envelope" />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    )}
                  </InputGroup>
                </div>
              </div>

              <div className="mt-4">
                <Form.Label>Password</Form.Label>
                <div className="mb-3">
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="password"
                      autoComplete="off"
                      onChange={(e) => {
                        handleChange(e)
                        setValidation(validatePassword(e.target.value));
                      }}
                      onFocus={() => {
                        setShowPasswordDetails(true)
                      }}
                      onBlur={() => {
                        setShowPasswordDetails(false)
                      }}
                      value={values.password}
                      maxLength={25}
                      // isValid={touched.password && !errors.password}
                      isInvalid={touched.password && !!errors.password}
                    />
                    {touched.password && errors.password ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    ) : (
                      <InputGroup.Append>
                        <InputGroup.Text>
                          {/* <i className="fas fa-lock" /> */}
                          <i
                            className={`fa ${
                              showPassword ? 'fa-eye' : 'fa-eye-slash'
                            }`}
                            onClick={() => setShowPassword((show) => !show)}
                            aria-hidden="true"
                          />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    )}
                  </InputGroup>
                </div>
              </div>
              {showPasswordDetails &&
              <div className="password-strength-bar">
        <div
          className="password-strength-indicator"
          style={{
            width: `${(validation?.score || 0) / 8 * 100}%`,
            backgroundColor: getStrengthColor(validation?.score || 0)
          }}
        ></div>
      </div>}

              <div className="mt-4">
                <div className="d-flex justify-content-end mb-4">
                  <Link id='forgot-pass-btn' to="/forgot-password">Forgot password?</Link>
                </div>

                <div className="">
                  <button
                    id='signin-btn'
                    className="filterButton w-100"
                    type="submit"
                  >
                    Sign in
                  </button>
                </div>
              </div>

              <div className='text-center mt-3'>
              {/* <Link to="#">Sign in with passkey?</Link> */}
              <br />
              {/* <Link to="#">Use single sign-on (SSO)</Link> */}

              </div>
            </form>
          </div>
        </Col>
        <Col className='col-12 welcomeContainerMobile'>
            <div style={{position: 'relative'}} className="login-download">
              <h4 className="download-title mb-3 mt-5">Download For:</h4>
              <div className="download-links d-flex justify-content-center">
                {/* <a href="#">Windows</a> */}
                <a href="#" onClick={() => handleAgentDownload()}>
                  Windows
                </a>
                {/* <a href="#">Mac</a>
                <a href="#">Linux</a> */}
              </div>
            </div>
            </Col>
      </Row>
    </div>
  );
};

export default Login;
