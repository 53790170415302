import React, {useState} from 'react';
import ToggleComponent from '../common/ToggleComponent';

type Props = {
  id: string;
  configName: string;
  configDisplayName: string;
  subId: string;
  enabled: boolean;
  onToggleChange: (configName: string, enabled: boolean) => void;
};

function AlertConfigCard({
  id,
  configName,
  configDisplayName,
  subId,
  enabled,
  onToggleChange
}: Props) {
  const [buttonEnable, setButtonEnable] = useState(enabled);
  const [first, setFirst] = useState(enabled)


  const handleSwitchChange = () => {
    const newEnabledValue = !first;
    setFirst(newEnabledValue);
    onToggleChange(configName, newEnabledValue);
  };

  return (
    <tr className={``}>
      <td className="pl-5">
        <p className="text-left mb-0">{configDisplayName}</p>
      </td>

      <td className="col-1">
        <label className="switchTwo">
          <input
            type="checkbox"
            checked={first}
            onChange={handleSwitchChange}
          />
          <span className="sliderTwo round">
            {first ? (
              <i className="fa-solid fa-check  customeTick"></i>
            ) : (
              <i className="fa-solid fa-minus customMinus"></i>
            )}
          </span>
        </label>
      </td>
    </tr>
  );
}

export default AlertConfigCard;
