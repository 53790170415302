import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { SingleValue } from 'react-select';


type OptionType = {
    value: string;
    label: string;
  }

  type DataItem = {
    id: string;
    alias: string | null;
  }

type Props = {
    data: any;
  onSelect: (selectedId: string) => void;
  clearInput: boolean;
    onClearComplete: () => void;
    isManagedEndPointPage:boolean;
    isReport?:boolean
}

function SearchDropDown({ data, onSelect,clearInput, onClearComplete ,isManagedEndPointPage,isReport=false}: Props) {
    const [inputValue, setInputValue] = useState('');
    const [selectedOption, setSelectedOption] = useState<SingleValue<OptionType>>(null);

console.log(inputValue)
    useEffect(() => {
        if (clearInput) {
            setInputValue('');
            setSelectedOption(null);
            onClearComplete();
        }
    }, [clearInput, onClearComplete]);

    const options: OptionType[] = data.map((item:DataItem) => ({
        value: item.id,
        label: item.alias ? item.alias : item.id,
      }));


    const singleOptions: OptionType[] = data.map((item:DataItem) => ({
        value: item.id,
        label: item.alias,
      }));


      
    const singleOptionsForReport: OptionType[] = [{ value: 'All', label: 'All' },...data.map((item:DataItem) => ({
      value: item.id,
      label: item.alias,
    }))];

    useEffect(() => {
      // Set default option to 'All' when it's a report
      if (isReport) {
        setSelectedOption(singleOptionsForReport[0]);
      }
    }, [isReport]);

      
    
      const handleChange = (selectedOption: SingleValue<OptionType>) => {
         setSelectedOption(selectedOption);
        if (selectedOption) {
            onSelect(selectedOption.value);
        } else {
            onSelect('');
        }
      };
  return (
    <Select
    options={isManagedEndPointPage ? options : isReport? singleOptionsForReport: singleOptions}
    value={selectedOption}
    onChange={handleChange}
    placeholder="Select an ID"
    isSearchable
    id='endpoint-id-option'
    inputValue={isReport ? '' : inputValue}
            onInputChange={(value) => setInputValue(value)}
  />
  )
}

export default SearchDropDown