import Container from '@app/components/CustomDash/Container';
import React, {useEffect, useState} from 'react';
import {Col, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import SearchDropDown from '../agents/SearchDropDown';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';
import {getPortsReports} from '@app/services/trustedSoftware';
import {getAgentEndpointIds} from '@app/services/agents';

type Props = {};

function PortsReport({}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  const [agentId, setAgentId] = useState('all');
  const [listingData, setListingData] = useState([]);
  const [filterlistingData, setFilterListingData] = useState([]);
  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);

  const [clearInput, setClearInput] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);

  const startIndex = (pageNumber - 1) * numberOfRecords;
  const endIndex = pageNumber * numberOfRecords;
  const paginatedListingData = listingData?.slice(startIndex, endIndex);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  const token = useSelector((state: any) => state.auth.token);

  const sortTableData = (column: any) => {
    const sortedData = [...listingData];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      if (sortOrder === 'asc' || sortOrder === null) {
        // sort aesc if the current order is asc or null
        sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
        setSortColumn(column);
        setSortOrder('asc');
      } else {
        sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
        setSortColumn(column);
        setSortOrder('desc');
      }
    }

    setListingData(sortedData);
  };

  const fetchServiceInventoryReports = () => {
    setListingData([]);
    getPortsReports(token, agentId)
      .then((res) => {
        const {data} = res;
        setListingData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getAllAgents = () => {
    const cachedData = localStorage.getItem('cachedEndpoints');

    if (cachedData) {
      setFilterListingData(JSON.parse(cachedData));
    } else {
      getAgentEndpointIds(token)
        .then((response) => {
          const {data} = response;
          setFilterListingData(data);
          localStorage.setItem('cachedEndpoints', JSON.stringify(data));
        })
        .catch((err) => {
          setFilterListingData([]);
        });
    }
  };

  useEffect(() => {
    if (paginatedListingData?.length === 0) {
      setPageNumber(1);
    }
  }, [paginatedListingData]);

  useEffect(() => {
    getAllAgents();
    fetchServiceInventoryReports();
  }, []);

  const handleClear = () => {
    getPortsReports(token, 'all')
      .then((res) => {
        const {data} = res;
        setListingData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSelect = (id: string) => {
    setAgentId(id);
  };

  const handleClearAgents = () => {
    setClearInput(true);
  };

  const handleClearComplete = () => {
    setClearInput(false);
  };

  const renderServiceReports = listingData
    .filter((item) => item !== '')
    .join(', ');
  return (
    <Container>
      <div>
        {/* <ContentHeader title="Agents" /> */}

        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <Col xs={12}>
              <div>
              <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
                <h3 className="subHeaders"> </h3>
                <div className="d-flex flex-wrap align-items-center justify-content-end">
                 
                  <button
                    className="reportsBackButton mr-1"
                    onClick={() => {
                      {
                        navigate('/reports');
                      }
                    }}
                    id="pr-reports-back-btn"
                  >
                      <i className="fa-solid fa-left-long"></i>
                    <span>Back</span>
                  </button>
                


                  

                  {/* <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="">filter to get the software information.</Tooltip>}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        setFilter(!filter);
                      }}
                      className="m-1 filterButton mainFilterButton"
                      id="pr-reports-filter-btn"
                    >
                      <i className="fa-solid fa-filter" />
                      <span className="ml-2">Filter</span>
                    </button>
                  </OverlayTrigger> */}
                </div>
              </div>
                {filter && (
                  <section
                    className={` FilterOption ${
                      darkmode && 'darkSecondaryWrapper'
                    } col-lg-10 col-md-12 mx-auto mb-0 d-flex  flex-column`}
                  >
                    <Form className="d-flex flex-wrap  ">
                      <Form.Group
                        controlId="role"
                        className="col-lg-4 col-md-5 col-12"
                      >
                        <Form.Label>EndpointID/ Hostname/Alias</Form.Label>
                        {/* <Form.Control
                        as="select"
                        value={agentId}
                        onChange={(e) => {
                          setAgentId(e.target.value);
                        }}
                        id='si-endpoint-id-option'
                      >
                        <option value="">--Select--</option>
                        {renderSoftwareAgentData}
                      </Form.Control> */}
                        <SearchDropDown
                          data={filterlistingData}
                          onSelect={handleSelect}
                          clearInput={clearInput}
                          onClearComplete={handleClearComplete}
                          isManagedEndPointPage={false}
                        />
                      </Form.Group>
                      {/* <Form.Group
                      controlId="role"
                      className="col-lg-3 col-md-3 col-12"
                    >
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="search"
                        placeholder="Enter city"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="role"
                      className="col-lg-3 col-md-3 col-12"
                    >
                      <Form.Label>Os Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={osType}
                        onChange={(e) => {
                          setOsType(e.target.value);
                        }}
                      >
                        <option value="">--Select--</option>
                        <option value="Windows">Windows</option>
                        <option value="macOS">macOS</option>
                        <option value="Ubuntu">Ubuntu</option>
                        <option value="Kali GNU/Linux">Kali GNU/Linux</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group
                      controlId="role"
                      className="col-lg-3 col-md-3 col-12"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        value={status}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >
                        <option value="">--Select--</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Control>
                    </Form.Group> */}
                    </Form>

                    {/* <div className="d-flex justify-content-start mt-4 pl-1">
                    <button
                      type="button"
                      className="m-1 pointer"
                      onClick={() => {
                        fetchFilteredAgents(token);
                      }}
                    >
                      Apply
                    </button>
                    <button
                      type="button"
                      className="m-1 pointer"
                      onClick={() => {
                        setAgentId('');
                        setCity('');
                        setStatus('');
                        setTimeout(() => {
                          getAllAgents();
                        }, 100);
                      }}
                    >
                      Clear
                    </button>
                    <button
                      type="button"
                      className="Close m-1 pointer"
                      onClick={() => {
                        setFilter(false);
                      }}
                    >
                      Close
                    </button>
                  </div> */}
                    <div className="d-flex flex-wrap justify-content-center mt-4 pl-1">
                      <button
                        type="button"
                        className=" pointer my-2 my-sm-0 alertFilterButton"
                        onClick={() => {
                          fetchServiceInventoryReports();
                        }}
                        id="pr-apply-btn"
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        className="mx-2 my-2 my-sm-0 pointer alertFilterButton"
                        onClick={() => {
                          handleClear();
                          handleClearAgents();
                        }}
                        id="pr-clear-btn"
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="Close my-2 my-sm-0  pointer alertFilterButton"
                        onClick={() => {
                          setFilter(false);
                        }}
                        id="pr-close-btn"
                      >
                        Close
                      </button>
                    </div>
                  </section>
                )}

                <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
                  <div className="d-flex tableContainer overflow-auto flex-column">
                    {/* <div className="rounded"> */}
                    <table
                      className={`customTable ${darkmode ? 'darkTable' : ''}`}
                    >
                      <thead className="">
                        <tr>
                          <th
                           
                          >
                            Unique open ports
                            
                          </th>
                        </tr>
                      </thead>
                      {!loading && paginatedListingData.length > 0 && (
                        <tbody className="">
                          <tr>
                            <td>{renderServiceReports}</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                    {/* </div> */}
                    {loading && (
                      <p
                        className={`mt-0 ${
                          darkmode ? 'noRecordsDark' : 'noRecords'
                        }`}
                      >
                        Loading...
                      </p>
                    )}
                    {!loading && paginatedListingData.length === 0 && (
                      <p
                        className={` mt-0 ${
                          darkmode ? 'noRecordsDark' : 'noRecords'
                        }`}
                      >
                        No Unique open ports Found..
                      </p>
                    )}
                    {!loading && paginatedListingData === null && (
                      <p
                        className={`mt-0 ${
                          darkmode ? 'noRecordsDark' : 'noRecords'
                        }`}
                      >
                        Network Error...
                      </p>
                    )}
                    {/* <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< Previous"
                    renderOnZeroPageCount={null}
                    containerClassName={'pagination'}
                    pageLinkClassName={'page-num'}
                    previousLinkClassName={'page-num'}
                    nextLinkClassName={'page-num'}
                    activeLinkClassName={'active'}
                  /> */}
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>

        {/* <UserFormModal
    show={showUserForm}
    onHide={() => setShowUserForm(false)}
  /> */}
      </div>
    </Container>
  );
}

export default PortsReport;
