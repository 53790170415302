import Container from '@app/components/CustomDash/Container';
import AlertConfigCard from '@app/components/settings/AlertConfigCard';
import { editAlertConfig, getAlertConfig } from '@app/services/settings';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

type Props = {};

function AlertConfig({}: Props) {

    const [settingData, setSettingData] = useState([]);
    const [settingDataEdit, setSettingDataEdit] = useState({});
    const token = useSelector((state: any) => state.auth.token);
    const darkmode = useSelector((state: any) => state.account.darkmode);


    const fetchAlertConfig = (token: string) => {
        getAlertConfig(token)
          .then((res) => {
            setSettingData(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };

    const editSettingData = () => {
        if (Object.keys(settingDataEdit).length > 0) {
            editAlertConfig(token, settingDataEdit)
              .then((response) => {
                toast.success("Config updated successfully:", response.data)
              })
              .catch((error) => {
                console.error("Error updating config:", error);
              });
          }
    }

      useEffect(() => {
        fetchAlertConfig(token);
      }, []);

      const handleToggleChange = (configName: string, enabled: boolean) => {
        setSettingDataEdit((prev) => ({
          ...prev,
          [configName]: enabled,
        }));
      };



      const renderData = settingData?.map((i: any, index: any) => (
        <AlertConfigCard
          id={i.id}
          configName={i.configName}
          configDisplayName={i.configDisplayName}
          subId={i.subId}
          enabled={i.enabled}
          onToggleChange={handleToggleChange}
        />
      ));
  return <Container>
    <div className=' mx-auto col-lg-10 d-flex  justify-content-end mt-5'>

        <button onClick={editSettingData} className='alertConfigSaveButton'>Save</button>
    </div>
     <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
  <div className="d-flex tableContainer overflow-auto flex-column">
    <table className={`customTable ${darkmode ? 'darkTable' : ''}`}>
      <thead className="">
        <tr>
          <th className="col-2 p-2 pl-5 text-left">Config Display Name</th>
          
          <th className="col-1 p-2"></th>
        </tr>
      </thead>
      <tbody>{renderData}</tbody>
    </table>
  </div>
</div></Container>;
}

export default AlertConfig;
